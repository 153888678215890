import classNames from "classnames";

import "./InputField.css";

const InputField = ({ attributes, modifier, events }) => {
  const { error, disabled, sublabel } = modifier;
  const hasError = error ? "input-has-error" : "";
  const isDisabled = disabled ? "disabled" : "";

  const { id, label, type, placeholder, maxLength, value, ...rest } =
    attributes;

  const { onChange, onFocus, onBlur } = events;

  const renderErrorMessage = () => {
    if (error) {
      return <p className="text-paprika text-xs pl-3 pb-4 pt-[2px]">{error}</p>;
    }

    return (
      <p className="text-pepper-light text-xs pl-3 pb-4 pt-[2px]">
        {sublabel}
      </p>
    );
  };

  return (
    <section className={`${classNames(hasError, isDisabled)}`}>
      <div>
        <label htmlFor="id" id={`label-${id}`} className="text-xs text-pepper">
          {label}
        </label>
      </div>
      <div className="input-wrapper relative overflow-hidden">
        <input
          className="block text-sm mt-1 px-3 py-4 bg-pepper-lighter rounded-lg w-full outline-none disabled:bg-pepper-disabled"
          id={id}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="anyrandomstring"
          maxLength={maxLength}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          {...rest}
        />
      </div>
      {renderErrorMessage()}
    </section>
  );
};

InputField.defaultProps = {
  attributes: {
    id: "abc123",
    label: "Label Text",
    maxLength: 100,
    value: "",
    type: "text",
    placeholder: "placeholder",
  },
  modifier: {
    error: "",
    disabled: false,
    sublabel: "",
  },
  events: {
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
  },
};

export default InputField;
