const Menu = ({ children }) => {
  return (
    <div className="flex justify-between items-center flex-1">
      <nav className="flex space-x-4 w-full">{children}</nav>
    </div>
  )
}
const Item = ({ modifier, children }) => {
  const { active } = modifier;
  return (
    <div className={`px-4 py-6 text-sm ${active ? "text-pandan-dark font-bold" : "text-pepper-light hover:text-pepper-dark"} hover:bg-pepper-lighter rounded`}>
      {children}
    </div>
  )
}
Item.defaultProps = {
  modifier: {
    active: false
  }
}

Menu.Item = Item;
export default Menu;