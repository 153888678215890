import { useSelector } from "react-redux";
import Loader from "components/Loader";
import Toast from "components/Toast";

const Wrapper = ({ children }) => {
  const { loading } = useSelector((state) => state.loading);

  const renderLoader = () => {
    if (loading) return <Loader />;

    return null;
  };

  return (
    <>
      {renderLoader()}
      <Toast />
      {children}
    </>
  );
};

export default Wrapper;
