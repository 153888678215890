const Content = () => {
  const taraliteLogo = require("assets/images/taralite.svg").default;

  return (
    <>
      <img className="h-[52px]" src={taraliteLogo} alt="Taralite Logo" />
      <h1 className="font-bold text-black text-2xl mt-4">
        Syarat dan Ketentuan
      </h1>
      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Mohon Diperhatikan
      </h2>
      <div className="flex w-full mt-3 text-sm text-justify">
        <ol className="list-decimal ml-4">
          <li>
            Pengguna Layanan Pinjam Meminjam Uang Berbasis Teknologi Informasi
            (“Fintech Lending”) merupakan wujud kesepakatan dan hubungan perdata
            antara Pemberi Pinjaman dengan Penerima Pinjaman, sehingga segala
            resiko dan akibat hukum daripadanya ditanggung sepenuhnya oleh
            masing-masing pihak yang berkontrak.
          </li>
          <li>
            Resiko Kredit atau Gagal Bayar dan seluruh kerugian dari atau
            terkait dengan kesepakatan pinjam meminjam ditanggung sepenuhnya
            oleh Pemberi Pinjaman. Tidak ada lembaga atau otoritas negara yang
            bertanggung jawab atas resiko gagal bayar dan kerugian tersebut.
          </li>
          <li>
            Pemberi Pinjaman yang belum memiliki pengetahuan dan pengalaman
            Pinjam- Meminjam atau Fintech lending, disarankan tidak menggunakan
            layanan ini.
          </li>
          <li>
            Sebelum memanfaatkan Fintech Lending, Penerima Pinjaman wajib
            mempertimbangkan tingkat bunga pinjaman dan biaya-biaya lainnya
            sesuai dengan kemampuannya dalam melunasi pinjaman.
          </li>
          <li>
            Setiap kecurangan tercatat secara digital di dunia maya dan dapat
            diketahui masyarakat luas di media sosial serta dapat menjadi alat
            bukti hukum yang sah menurut peraturan mengenai informasi dan
            transaksi elektronik dalam proses penyelesaian sengketa dan
            penegakan hukum.
          </li>
          <li>
            Masyarakat Pengguna wajib membaca dan memahami informasi ini sebelum
            keputusan sebagai Pemberi Pinjaman maupun Penerima Pinjaman.
            Keputusan Pengguna untuk memanfaatkan Fintech Lending merupakan
            suatu wujud dan bukti pemahaman Pengguna atas Informasi ini.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Ketentuan Penggunaan Situs Web
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Persyaratan ini (dan seluruh dokumen yang terkait dengan hal
            tersebut) menerangkan kepada Anda, sebagai klien (yang selanjutnya
            hanya akan disebut "Anda" saja) tentang persyaratan penggunaan situs
            www.taralite.com (Situs Web), baik sebagai pengunjung atau sebagai
            anggota terdaftar. Situs Web ini dioperasikan oleh PT Indonusa Bara
            Sejahtera (yang selanjutnya disebut dengan "kami").
          </li>
          <li>
            Bacalah persyaratan penggunaan ini dengan baik sebelum Anda
            menggunakan Situs Web kami. Dengan menggunakan Situs Web kami,
            berarti Anda telah menyetujui seluruh penafian, persyaratan dan
            ketentuan penggunaan (selanjutnya disebut dengan "Persyaratan" saja)
            situs ini. Jika Anda tidak menyetujuinya, maka sebaiknya sesegera
            mungkin keluar dari Situs Web ini dan menahan diri untuk tidak
            menggunakan Situs Web ini kembali.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Informasi Tentang Kami
      </h2>
      <div className="flex w-full mt-3 text-sm text-justify">
        Kami terdaftar dengan nama PT Indonusa Bara Sejahtera di Negara Republik
        Indonesia dan telah mendaftarkan kantor kami yang berada di Rukan Golden
        8 no. 8C, Jalan Panjang, Kedoya Utara Jakarta Barat, DKI Jakarta,
        Indonesia 11520
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Pengguna Situs Web
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Anda bertanggung jawab sepenuhnya atas penggunaan Situs Web ini baik
            bila Anda mengakses situs kami melalui perangkat komputer
            ("perangkat komputer" yang dimaksud termasuk akses dari telepon
            genggam). Dengan mengakses Situs Web ini, siapa saja yang
            menggunakan perangkat komputer Anda setuju untuk terikat dengan
            persyaratan kami. Anda bertanggung jawab untuk memastikan semua
            orang yang mengakses Situs Web kami melalui koneksi internet dan
            menggunakan perangkat komputer Anda, mengetahui dan mematuhi
            persyaratan kami.
          </li>
          <li>
            Situs Web ini ditujukan untuk digunakan bagi Anda yang berusia 18
            tahun atau lebih dan mengaksesnya dari Negara Republik Indonesia.
            Kami tidak dapat menjamin bahwa informasi yang berada pada Situs Web
            ini memenuhi atau sesuai dengan peraturan di luar Republik
            Indonesia.
          </li>
          <li>
            Untuk tedaftar pada kami, atau mengajukan pinjaman kepada kami, Anda
            harus berusia 18 tahun atau lebih. Anda harus memastikan bahwa detil
            informasi yang Anda berikan, baik pada saat registrasi maupun
            pendaftaran atau kapan saja saat Anda mengakses Situs Web kami,
            haruslah akurat dan lengkap. Anda harus segera menginformasikan
            kepada kami jika ada perubahan dengan cara memperbaharui detail data
            pribadi Anda.
          </li>
          <li>
            Anda menyetujui bahwa Anda tidak akan secara tak sengaja mengirimkan
            virus atau apapun yang akan merusak Situs Web kami. Anda tidak
            diperkenankan untuk menggunakan program, perangkat atau software
            otomatis untuk mengganggu atau berusaha mengganggu sistem yang
            berkerja pada Situs Web ini. Anda juga tidak diizinkan untuk
            menguraikan, mendekompilasi, membongkar, atau merekayasa balik
            setiap bagian dari perangkat lunak yang menyusun atau bagaimanapun
            membentuk Situs Web ini.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Pengaksesan Situs Web
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Akses pada Situs Web ini dizinkan dengan persyaratan sementara, dan
            kami berhak untuk menarik atau mengubah layanan yang kami sediakan
            di Situs Web ini tanpa pemberitahuan (lihat di bawah). Kami tidak
            memberikan jaminan jika karena alasan apapun Situs Web tidak
            tersedia setiap saat atau selama beberapa waktu.
          </li>
          <li>
            Adakalanya kami melarang akses pada sebagian, atau keseluruhan Situs
            Web bagi beberapa pengguna yang telah terdaftar.
          </li>
          <li>
            Jika Anda memilih, atau telah disediakan, password atau informasi
            apapun sebagai bagian dari prosedur keamanan, maka Anda harus
            menyimpan informasi rahasia tersebut, dan tidak boleh membocorkannya
            kepada pihak ketiga. Kami memiliki hak untuk menonaktifkan password
            pengguna, meskipun Anda telah menunjuk atau ditunjuk oleh kami,
            setiap saat, jika menurut kami Anda telah gagal untuk mematuhi mana
            saja ketetapan dari persyaratan ini.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Situs Web Pihak Ketiga
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Situs Web ini adakalanya terkait dengan Situs Web lain. Keterkaitan
            tersebut diluar kendali dan tanggung jawab kami. Kami tidak menerima
            jaminan atas isi atau ketersediaan situs terkait yang tidak
            dioperasikan oleh kami. Kaitan pada Situs Web kami, sediakan hanya
            untuk kenyamanan Anda dan kami tidak mengindikasikan kepercayaan
            atau persetujuan atas situs terkait. Untuk itu, Anda sebaiknya
            selalu merujuk kepada syarat dan ketentuan yang ada pada situs
            terkait sebelum Anda menggunakan situs tersebut dan ajukanlah
            pertanyaan atau komentar Anda langsung kepada penyedia Situs web
            tersebut.
          </li>
          <li>
            Anda tidak diizinkan (dan juga Anda tidak diizinkan membantu orang
            lain) untuk membuat link dari Situs Web Anda ke Situs Web kami
            (dengan cara apapun) tanpa persetujuan tertulis, yang kami bisa
            berikan atau tolak sesuai dengan kebijakan kami. Anda tidak
            diizinkan untuk membuat tautan langsung ("Hot Link") terhadap konten
            atau gambar tanpa izin tertulis dari kami terlebih dahulu.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Surat Elektronik Internet
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Pesan yang disampaikan melalui koneksi internet tidak dapat dijamin
            sepenuhnya keamanannya, dan bisa saja tertahan, hilang atau
            mengalami perubahan. Kami tidak bertanggung jawab atas pesan yang
            hilang, diubah oleh pihak ketiga, atau tertahan dan kami tidak
            bertanggung jawab secara material kepada Anda atau siapa saja atas
            kerusakan atau hal lainnya sehubungan dengan pesan yang dikirim oleh
            Anda kepada kami atau kami kepada Anda melalui koneksi internet.
          </li>
          <li>
            Situs Web ini menggunakan cookie, yang harus Anda setujui
            penggunaannya untuk menikmati fungsionalitas penuh dari Situs Web
            ini. Cookie adalah file yang digunakan oleh peladen kami untuk
            mengidentifikasi komputer Anda. Cookie yang kami gunakan akan
            merekam bagian mana dari Situs Web ini yang Anda kunjungi dan berapa
            lama. Anda berhak untuk menolak penggunaan cookie dengan cara
            mengkonfigurasi web jelajah Anda. Mohon untuk diingat, bahwa
            konfigurasi tersebut bisa saja mengganggu beberapa fungsi dari Situs
            Web ini. Untuk informasi lebih lanjut terkait penggunaan cookie ini,
            silahkan melihat bagian Kebijaksanaan Privasi kami.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Hak Kekayaan Intelektual
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Situs Web ini dimiliki oleh PT Indonusa Bara Sejahtera. Apapun dan
            seluruh hak kekayaan intelektual dalam situs web ini termasuk, namun
            tidak terbatas, pada hak cipta, dan hak basis, data serta seluruh
            logo atau merek dagang (apakah terdaftar ataupun tidak) menjadi
            milik dan properti tetap dari PT Indonusa Bara Sejahtera (atau
            lisensi pihak ketiga yang dipakai) sampai kapan pun juga.
          </li>
          <li>
            Anda diperbolehkan untuk melihat dan menyalin-cetak diatas kertas
            isi yang disediakan Situs Web, namun:
            <ol
              className="list-[lower-alpha] ml-4 text-sm text-justify"
              type="a"
            >
              <li>
                Seluruh dan salinan tersebut merupakan hak kekayaan intelektual
                dari PT Indonusa Bara Sejahtera dan pihak ketiga yang kami
                berikan lisensi.
              </li>
              <li>
                Anda tidak mengubah dengan cara apapun salinan kertas dari
                bahan-bahan yang telah dicetak, termasuk penghapusan segala hak
                cipta atau kepemilikan lainnya yang terkandung di situs.
              </li>
            </ol>
          </li>
          <li>
            Anda setuju tidak akan menggunakan bagian manapun dari Situs web ini
            untuk:
            <ol
              className="list-[lower-alpha] ml-4 text-sm text-justify"
              type="a"
            >
              <li>
                Menyelipkan atau dengan sengaja atau tidak sengaja menularkan
                atau menyebarkan virus, worm, trojan horse, time bomb, trap door
                atau kode komputer, berkas, atau program atau membuat permintaan
                berulang yang dirancang untuk mengganggu, merusak atau membatasi
                fungsi dari perangkat lunak, atau perangkat keras komputer, atau
                peralatan telekomunikasi, atau untuk mengurangi kualitas,
                mengganggu tampilan dari, atau merusak fungsi Situs web ini.
              </li>
              <li>
                Mengunggah, memasang, mengirimkan surel atau sebaliknya
                menerima, atau menempelkan tautan pada konten apapun yang
                memfasilitasi peretasan data.
              </li>
              <li>Meretas apapun yang menjadi bagian dari Situs web ini.</li>
              <li>
                Mengunggah, mengirimkan surel, atau mengirimkan tautan kepada
                konten mana saja yang melanggar hak kekayaan intelektual dari
                pihak ketiga.
              </li>
              <li>
                Mengelakkan atau mencoba mengelakkan, cara pengamanan apapun
                dari Situs Web, atau
              </li>
              <li>
                Menginjinkan pihak ketiga mana pun untuk melakukan semua hal
                diatas.
              </li>
              <li>
                Jika Anda menggunakan konten dari Situs Web ini namun gagal
                memenuhi persyaratan-persyaratan ini, maka hak Anda untuk
                menggunaka situs web ini akan langsung hilang.
              </li>
              <li>
                Tidak ada tautan dari situs lain pada Situs Web ini yang mungkin
                masuk tanpa izin tertulis dari kami terlebih dahulu. Anda tidak
                berhak memberi nama memodifikasi, atau membagikan kembali konten
                dari situs ini.
              </li>
            </ol>
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Perlindungan dan Kerahasiaan Data
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Keberadaan kami bisa merupakan hasil dari interaksi Anda dengan
            Situs Web ini, dan mengadakan, serta memproses informasi yang
            diperoleh tentang Anda ketika Anda meng-akses SitusWeb ini, dan
            menggunakannya untuk membuat keputusan pinjaman, dan hubungan
            pelayanan kami dengan Anda, demi fungsi pencegahan penipuan dan
            pengumpulan hutang, untuk mengetahui kebutuhan keuangan Anda,
            terkait dengan usaha kami dan untuk memberikan layanan pelanggan
            yang lebih baik dan produk yang tepat dari pihak ketiga, untuk
            mengevaluasi keefektifan dari pemasaran Situs Web kami, dan untuk
            analisa statistik. Kami mungkin meneruskan infomasi kepada agen-agen
            kami, sesuai dengan ketentuan hukum, dan mereka juga dapat
            meneruskan informasi yang mereka miliki tentang Anda kepada kami,
            dan kami juga dapat melakukan hal yang sama. Kami tidak akan membuka
            informasi apapun diluar PT Indonusa Bara Sejahtera kecuali untuk
            kepentingan pencegahan penipuan dan/ atau bila diminta dan/ atau
            diwajibkan oleh hukum dan pemerintah, atau badan hukum, atau agen,
            atau lembaga berwenang di bawah aturan yang ada, atau di bawah kode
            rahasia untuk meng-sub kontrakkan, atau orang yang bertindak sebagai
            agen kami atau apa saja yang kami beri wewenang.
          </li>
          <li>
            Dengan mendaftarkan semua informasi melalui situs web ini, Anda
            setuju bahwa PT Indonusa Bara Sejahtera dapat menghubungi Anda kapan
            saja melalui surat, faksimili, surel ataupun telepon.
          </li>
          <li>
            Semua informasi yang dimasukkan dalam formulir pada situs ini
            disimpan dengan aman dan rahasia oleh PT Indonusa Bara Sejahtera
            sesuai dengan ketentuan perlindungan data lokal di Indonesia. Kami
            mensyaratkan semua pihak yang kami sampaikan informasi Anda untuk
            menyimpannya dengan tingkat kerahasiaan yang sama. Kami juga telah
            mengambil semua langkah yang wajar secara komersial untuk memastikan
            bahwa setiap informasi yang dikirimkan pada website dilindungi dan
            dienkripsi termasuk namun tidak terbatas pada penggunaan teknologi
            SSL / TLS yang tersedia untuk penggunaan komersial.
          </li>
          <li>
            Kami menjaga privasi data yang sangat serius. Anda dapat meninjau
            kebijakan privasi kami rinci di
            <a
              className="text-pandan-dark underline"
              target="_blank"
              rel="noreferrer"
              href="https://www.taralite.com/assets/documents/privacy-policy.pdf"
            >
              {" "}
              https://www.taralite.com/assets/documents/privacy-policy.pdf
            </a>{" "}
            atau Anda dapat menghubungi kami di
            <a
              className="text-pandan-dark"
              target="_blank"
              rel="noreferrer"
              href="mailto:contact@taralite.com"
            >
              {" "}
              contact@taralite.com
            </a>{" "}
            untuk informasi lebih lanjut tentang bagaimana kami menangani atau
            memproses informasi pribadi Anda.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Penafian dan Pembatasan (Liability)
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Kami telah mengambil langkah yang bertanggung jawab untuk memastikan
            keakuratan, ketepatan, kebenaran dan kelengkapan informasi dari
            Situs Web ini. Namun, bagaimanapun juga, informasi yang ada pada
            situs ini, dan disebut dengan “sebagaimana yang ada” atau
            “sebagaimana arti” hanya merupakan dasar, dan kami tidak memberikan
            jaminan atau mewakili apapun, meskipun hal tersebut terlihat jelas
            atau tersirat.
          </li>
          <li>
            Penggunaan dari Situs Web ini dan informasi yang terkandung
            didalamnya adalah tanggung jawab risiko Anda sendiri. Kami tidak
            menjamin kehilangan atau kerusakan, apakah langsung atau tidak
            langsung, sebagai akibat atau sebaliknya, yang mungkin membuat Anda
            kesulitan untuk menggunakan Situs Web ini; termasuk, namun tidak
            terbatas pada layanan komputer, atau kegagalan sistem, akses yang
            tertunda, atau terganggu, data yang tidak terkirim atau salah
            pengiriman virus komputer, atau komponen lain yang merusak,
            melanggar keamanan, atau sistem tak bertanggung jawab yang berasal
            dari datangnya "peretas" atau sebaliknya, atau kepercayaan Anda pada
            informasi yang ada pada Situs Web ini.
          </li>
          <li>
            Kami tidak mewakili atau menjamin Situs Web ini selalu tersedia dan
            memenuhi kebutuhan Anda, seperti terganggunya akses, tidak akan
            adanya penundaan, kegagalan, eror atau penghilangan atau kehilangan
            dari informasi yang dikirimkan; tidak akan ada virus atau
            kontaminasi atau hal-hal yang merusak, tidak akan terkirim atau
            tidak akan ada kerusakan pada sistem komputer Anda. Anda bertanggung
            jawab sendiri untuk perlindungan yang sesuai atas data dan atau
            peralatan, dan untuk mengambil tindakan yang bertanggung jawab dan
            sesuai sebagai pencegahan dan memindai virus komputer atau hal-hal
            perusak lainnya.
          </li>
          <li>
            Kami tidak membuat perwakilan atau garansi terkait dengan
            keakuratan, fungsi, tampilan perangka lunak dari pihak ketiga
            manapun yang mungkin digunakan untuk koneksi pada Situs Web ini.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Peraturan Pemerintah
      </h2>
      <div className="flex w-full mt-3 text-sm text-justify">
        Persyaratan ini ditentukan dan diterjemahkan sesuai dengan peraturan
        pemerintah Republik Indonesia. Pihak-pihak yang dengan ini, tunduk
        kepada yurisdiksi non-eksklusif dari Pengadilan Negeri Jakarta Barat.
        Meskipun begitu perjanjian ini bisa saja berlaku di pengadilan manapun
        di setiap yurisdiksi yang kompeten seperti yang kami dapat tentukan
        sewaktu-waktu.
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">Pelepasan</h2>
      <div className="flex w-full mt-3 text-sm text-justify mb-6">
        Kegagalan kami untuk menggunakan atau menunda dalam melaksanakan hak,
        kekuasaan atau hak istimewa berdasarkan Perjanjian ini bukanlah sebuah
        pengabaian; begitu juga terhalanganya salah satu atau sebagian dari hak,
        kekuasaan atau hak istimewa manapun atau penggunaannya.
      </div>

      <img className="h-[52px] mt-16" src={taraliteLogo} alt="Taralite Logo" />
      <h1 className="font-bold text-black text-2xl mt-4">Kebijakan Privasi</h1>
      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Kebijakan Privasi Taralite
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Ini merupakan Kebijakan Privasi taralite.com. Kami berkomitmen untuk
            melindungi privasi dan keamanan klien kami. Kami ingin meyakinkan
            semua pihak bahwa kami tidak pernah dan tidak akan pernah menjual
            data klien kami ke pihak manapun yang tidak berkepentingan karena
            kami menjunjung tinggi privasi para klien kami.
          </li>
          <li>
            Data diri serta data pribadi yang dicantumkan para klien kami dalam
            proses pengisian aplikasi sepenuhnya kami gunakan untuk proses
            verifikasi kredit. Seluruh data tersebut dijaga kerahasiaannya,
            tidak akan dijual atau dibagikan kepada pihak manapun demi
            kepentingan komersil.
          </li>
          <li>
            Kami juga perlu menjelaskan bahwa, seperti halnya bank dan institusi
            keuangan lainnya, kami diwajibkan oleh hukum yang berlaku untuk
            membuka data kepada pihak ketiga yang memiliki kewenangan seperti
            pemerintah, hanya jika ada perintah yang sah dari pengadilan untuk
            pengecekan kredit dan verifikasi. Kami menjaga privasi klien kami
            dengan sangat serius. Mohon baca kebijakan di bawah ini dengan
            jelas.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Informasi yang Kami Kumpulkan dari Anda
      </h2>
      <div className="flex w-full mt-3 flex-col text-sm">
        Kami mungkin mengumpulkan dan memproses informasi tentang Anda sebagai
        berikut:
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Informasi yang kami peroleh langsung dari Anda melalui aplikasi
            online Anda; informasi tersebut kami peroleh melalui formulir online
            atau isian yang kami sediakan sebelumnya pada Situs Web kami, atau
            dari informasi yang Anda berikan saat menghubungi Departemen
            Pelayanan Pelanggan atau customer service kami;
          </li>
          <li>
            Percakapan telepon Anda dengan kami dan Departemen Pelayanan
            Pelanggan yang dimonitor dan direkam;
          </li>
          <li>
            Informasi dari sumber luar seperti agen referensi kredit dan
            penyedia layanan pelanggan terkait kelayakan dan sejarah kredit;
          </li>
          <li>
            Informasi yang dikumpulkan ketika Anda menggunakan Situs web yang
            mungkin kami gunakan untuk mengidentifikasi Anda dan mengesahkan
            penggunaan Situs web untuk tujuan pencegahan penipuan;
          </li>
          <li>Informasi tentang transaksi termasuk saldo kredit Anda;</li>
          <li>
            Informasi tentang komputer Anda, termasuk alamat IP, sistem operasi
            dan browser yang Anda gunakan. Ini adalah data statistik tentang
            tindakan penjelajahan dan pola dan tidak berupa identifikasi secara
            individual;
          </li>
          <li>
            Informasi diperoleh secara otomatis menggunakan “cookies” saat
            browser Anda mengakses dan Anda masuk (log-in) pada Situs web kami.
            Jika difungsikan (enabled), cookies akan menyimpan sejumlah kecil
            data pada komputer Anda tentang kunjungan Anda ke beberapa halaman
            Situs Web ini. Cookies akan membantu kami untuk menelusuri fitur
            kami yang paling menarik untuk Anda dan konten apa yang pernah Anda
            kunjungi di waktu lalu. Saat Anda mengunjungi situs ini lagi,
            cookies akan memudahkan kami untuk menyesuaikan konten sesuai dengan
            kebutuhan Anda. Cookies ini tidak melacak informasi pengguna secara
            individual, dan semua data cookie kami telah dienkripsi dan tidak
            dapat dibaca oleh Situs Web lain. Sangat mungkin bagi Anda untuk
            mematikan fungsi cookies pada browser Anda dan ini tidak akan
            memengaruhi fungsi dari Situs Web ini.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Tempat Penyimpanan dan Proses Data Pribadi Anda
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Semua informasi yang Anda berikan kepada kami disimpan dengan aman
            di peladen di negara Indonesia. Semua jenis transaksi pembayaran
            akan dienskripsi menggunakan teknologi SSL. Saat kami memberikan
            Anda (atau Anda telah memilih) sebuah kata sandi yang membuat Anda
            mudah untuk mengakses bagian tertentu dari Situs Web kami, Anda
            bertanggung jawab untuk memberlakukan sandi tersebut sebagai sesuatu
            yang sangat rahasia. Kami mohon untuk tidak membagi sandi tersebut
            dengan siapa pun.
          </li>
          <li>
            Sayangnya, pengiriman informasi melalui internet tidak sepenuhnya
            aman. Meskipun kami telah melakukan hal yang terbaik untuk
            melindungin data pribadi Anda, kami tidak dapat menjamin keamanan
            data Anda yang dikirim melalui situs kami, pengiriman apapun adalah
            tanggung jawab Anda. Begitu kami menerima informasi Anda, kami akan
            menggunakan prosedur yang ketat dan fitur keamanan sebagai usaha
            untuk mencegah akses yang tidak bertanggung jawab.
          </li>
          <li>
            Sistem keamanan kami telah memenuhi atau melampaui standar industri
            dan kami senantiasa mengamati perkembangan internet guna memastikan
            sistem kami berkembang seperti yang disyaratkan. Kami juga melakukan
            tes terhadap sistem kami secara berkala untuk memastikan mekanisme
            keamanan kami selalu mutakhir.
          </li>
          <li>
            Akhirnya, kami tunduk kepada hukum perlindungan data di Indonesia
            dan sepenuhnya mematuhi serta memberikan rasa hormat terbesar.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Bagaimana Kami Menggunakan Data Pribadi Anda
      </h2>
      <div className="flex w-full mt-3 flex-col text-sm">
        Informasi tentang Anda yang telah kami kumpulkan akan direkam secara
        elektronik dan mungkin digunakan untuk beberapa tujuan:
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Untuk memproses transaksi pinjaman dan memutakhirkan akun data Anda
            yang kami simpan.
          </li>
          <li>
            Untuk keperluan bisnis kami secara umum misalkan untuk verifikasi
            identitas Anda dan reputasi kredit Anda.
          </li>
          <li>
            Untuk menaksir sejarah kredit Anda dan mengkonfirmasi detil
            pekerjaan Anda.
          </li>
          <li>
            Untuk menghubungi pihak ketiga, misalkan atasan tempat Anda bekerja,
            yang telah Anda ajukan untuk mengkonfirmasi informasi tentang diri
            Anda.
          </li>
          <li>Untuk membuat keputusan pinjaman.</li>
          <li>
            Untuk membuat analisa statistik dan mengembangkan juga meningkatkan
            produk kami untuk memperkirakan risiko pinjaman.
          </li>
          <li>
            Untuk mengidentifikasi Anda ketika Anda menghubungi kami atau
            mengunjungi Situs Web kami.
          </li>
          <li>
            Untuk mengidentifikasi produk kami atau produk rekan kami yang
            menarik Anda.
          </li>
          <li>
            Untuk mencegah dan mendeteksi penipuan, ini termasuk penggunaan
            teknologi deteksi suara guna analisa rekaman percakapan telepon Anda
            dengan kami.
          </li>
          <li>Untuk menghubungi Anda terkait dengan permintaan Anda.</li>
          <li>
            Untuk memutakhirkan Situs kami guna memenuhi kebutuhan klien kami
            dimasa yang akan datang.
          </li>
          <li>
            Untuk memastikan bahwa konten pada situs ini disajikan dengan cara
            yang paling efektif untuk Anda dan komputer Anda.
          </li>
          <li>
            Untuk menyelesaikan kewajiban kami yang timbul karena adanya kontrak
            yang masuk antara Anda dengan kami.
          </li>
          <li>
            Untuk mengizinkan Anda berpartisipasi dalam layanan fitur interaktif
            kami, bila Anda memilih utuk melakukannya.
          </li>
          <li>
            Untuk mengevaluasi keefektifan dari pemasaran situs web kami, untuk
            pengecekan berkala dan untuk riset pemasaran.
          </li>
          <li>
            Untuk mengizinkan bank, badan keuangan atau pihak ketiga yang
            berwenang guna mengadakan pengecekan terbatas pada status Anda dalam
            data base kami atau layanan kami.
          </li>
          <li>Untuk memberitahu Anda tentang perubahan layanan.</li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Penggunaan Informasi Anda untuk Kegunaan Pemasaran - Hak-hak Anda
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Dengan mengunjungi Situs Web kami, maka Anda mengetahui dan setuju
            bahwa kami boleh menghubungi Anda malalui nomor telepon atau alamat
            surel yang Anda masukkan pada aplikasi pinjaman untuk membantu Anda
            dengan pinjaman atau berdiskusi untuk produk yang lain dan pelayanan
            lain yang taralite.com dapat berikan untuk Anda.
          </li>
          <li>
            Anda memiliki hak untuk bertanya kepada kami bagaimana proses data
            pribadi digunakan untuk kepentingan pemasaran. Kami biasanya
            menginformasikan kepada Anda (sebelum kami mulai mengumpulkan data
            Anda) jika kami hendak memakai data untuk kepentingan semacam itu.
          </li>
          <li>
            Situs kami bisa, sewaktu-waktu, mengandung tautan ke dan dari situs
            web dari rekan jejaring, pengiklan dan afiliasi kami. Jika Anda
            mengikuti salah satu tautan dari bagian mana saja pada situs web
            ini, mohon untuk dicatat bahwa situs web (pada link tersebut)
            memiliki kebijakan privasi dan kami tidak menerima
            pertanggungjawaban apapun atau jaminan untuk kebijakan-kebijakan
            pada situs web itu.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Pembukaan Informasi Anda
      </h2>
      <div className="flex w-full mt-3 flex-col text-sm text-justify">
        Kami mungkin membuka informasi pribadi Anda kepada sub-kontraktor kami,
        agen, penyedia layanan, peminjam dan anggota dari grup perusahaan kami
        dengan tujuan untuk menyediakan beberapa layanan untuk Anda hanya untuk
        kepentingan verifikasi. Detail:
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Kami tidak pernah dan tidak akan pernah menjual data klien kami ke
            pihak manapun yang tidak berkepentingan karena kami menjunjung
            tinggi privasi para klien kami.
          </li>
          <li>
            Kami membuka dan menggunakan data Anda hanya untuk kepentingan
            verifikasi, dimana data ini diberikan kepada pihak ketiga yang kami
            tunjuk untuk melakukan proses verifikasi.
          </li>
          <li>
            Kami tidak memberikan atau menjual data Anda kepada pihak ketiga
            tanpa adanya persetujuan dari Anda serta tujuan yang jelas.
          </li>
          <li>
            Jika Anda memberikan kami informasi salah dan tidak akurat dan/ atau
            kami menduga adanya penipuan, kami bisa mencatat hal tersebut dalam
            sejarah kredit Anda dan mungkin membagikan informasi tersebut ke
            badan hukum yang tepat atau pihak yang berwenang dan/ atau agensi
            pencegah penipuan.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Mode Sosial dan Jaringan Data
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Selama mengajukan pinjaman pada taralite.com, Anda mungkin diminta
            untuk memberikan kami izin untuk mengakses ke akun media sosial
            seperti Facebook, Twitter, Google+ dan / atau LinkedIn.
          </li>
          <li>
            Anda harus memahami bahwa sementara memberikan akses ke data akun
            media sosial Anda akan meningkatkan kesempatan Anda mendapatkan
            pinjaman lebih murah dengan kami, itu tidak wajib bagi Anda untuk
            memberikan kami akses ke data akun media sosial Anda. Anda dapat
            melanjutkan dengan aplikasi pinjaman tanpa data tersebut.
          </li>
          <li>
            Dalam hal Anda memberikan kami akses ke data akun media sosial Anda,
            Anda harus memahami bahwa kami hanya menggunakan data tersebut untuk
            tujuan berikut:
            <ol className="list-[lower-alpha] ml-4">
              <li>Verifikasi identitas;</li>
              <li>Mitigasi risiko penipuan identitas;</li>
              <li>
                Penilaian risiko kredit untuk proses underwriting pinjaman kami;
              </li>
              <li>Statistik analisis/kuantitatif;</li>
              <li>Melakukan kontrol regulasi;</li>
              <li>Memberikan informasi dan menjawab pertanyaan; dan</li>
              <li>
                Mencegah penipuan, pencucian uang dan kegiatan kriminal lainnya.
              </li>
            </ol>
          </li>
          <li>
            Lebih khusus, Anda harus memahami bahwa kami dapat menggunakan titik
            data yang kami peroleh di akun sosial media(s) untuk melakukan
            analisis kuantitatif internal untuk menilai kelayakan kredit Anda.
          </li>
          <li>
            Anda juga setuju bahwa jika Anda secara eksplisit memberikan kami
            izin untuk mengakses data akun media sosial Anda, Anda dengan ini
            juga secara eksplisit memberikan izin untuk jaringan media sosial,
            sebagaimana berlaku, untuk berbagi dan mengirimkan data tersebut ke
            kami.
          </li>
          <li>
            Kami tidak akan menggunakan data Anda untuk tujuan lain yang tidak
            disebutkan di atas termasuk namun tidak terbatas pada:
          </li>
          <li>
            Berbagi atau menjual data Anda langsung atau tidak langsung kepada
            pihak ketiga.
          </li>
          <li>
            Posting informasi apapun untuk atau akun media sosial Anda termasuk
            namun tidak terbatas pada fakta bahwa Anda memperoleh pinjaman dari
            kami di Taralite.
          </li>
          <li>Menghubungi setiap koneksi Anda pada jaringan sosial.</li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">Usia</h2>
      <div className="flex w-full mt-3 text-sm text-justify">
        Untuk terdaftar pada kami Anda harus berusia 18 tahun ke atas. Di bawah
        itu sangat tidak diizinkan untuk menggunakan pelayanan kami.
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Perubahan pada Kebijakan Privasi Kami
      </h2>
      <div className="flex w-full mt-3 text-sm text-justify">
        Perubahan apapun yang kami buat dalam kebijakan privasi kami di masa
        yang akan datang akan dipasang pada halaman ini, bila perlu, dan
        diberitahukan kepada Anda melalui surel.
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Penggabungan
      </h2>
      <div className="flex w-full mt-3 text-sm text-justify">
        Untuk menghindari adanya keraguan, Kebijakan Privasi ini haruslah
        digabungkan dengan persyaratan penggunaan situs Web kami. Dan, jika Anda
        akhirnya mengambil pinjaman dari Taralite, maka kebijakan ini harus
        digabungkan juga dengan Persetujuan Pinjaman milik PT Indonusa Bara
        Sejahtera.
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Hubungi Kami
      </h2>
      <div className="flex w-full mt-3 flex-col">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Jika Anda memiliki pertanyaan atau hal lain terkait penggunaan
            informasi pribadi Anda seperti yang telah disampaikan di atas,
            silahkan hubungi kami di:
          </li>
          <li>
            PT Indonusa Bara Sejahtera
            <ol className="list-[lower-alpha] ml-4">
              <li>Taralite Phone (0811-8181-020)</li>
              <li>Taralite Office (021-292-00-955)</li>
              <li>CS Email (customer@taralite.com)</li>
            </ol>
          </li>
        </ol>
      </div>

      <img className="h-[52px] mt-16" src={taraliteLogo} alt="Taralite Logo" />
      <h1 className="font-bold text-black text-2xl mt-4">
        Syarat dan Ketentuan OVO Modal Usaha - Invoice Financing
      </h1>
      <div className="text-sm text-justify mt-4">
        <p>
          Syarat dan Ketentuan OVO Modal Usaha merupakan bagian dari Syarat dan
          Ketentuan Situs PT Indonusa Bara Sejahtera (“Taralite”). Taralite
          adalah Penyelenggara Layanan Pinjam Meminjam Uang Berbasis Teknologi
          Informasi (LPMUBTI) yang berizin dan diawasi oleh Otoritas Jasa
          Keuangan (OJK). Pengajuan dan Penggunaan OVO Modal Usaha tunduk pada
          Kebijakan Privasi pada situs Taralite dan Syarat dan Ketentuan yang
          tertulis di bawah ini. Pengguna disarankan membaca dengan seksama
          karena dapat berdampak kepada hak dan kewajiban Pengguna secara hukum.
        </p>
        <p className="mt-4">
          Dengan mendaftar dan/atau menggunakan situs www.taralite.com, maka
          Pengguna dianggap telah membaca, mengerti, memahami dan menyetujui
          semua isi dalam Syarat dan Ketentuan. Syarat dan Ketentuan ini
          merupakan bentuk kesepakatan yang dituangkan dalam sebuah perjanjian
          yang sah antara Pengguna dengan Taralite. Jika Pengguna tidak
          menyetujui salah satu, sebagian, atau seluruh isi Syarat dan
          Ketentuan, maka Pengguna tidak diperkenankan menggunakan layanan di
          www.taralite.com.
        </p>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">Definisi</h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            OVO Modal Usaha adalah program pendanaan dengan metode Invoice
            Financing, yang ditawarkan oleh Taralite kepada calon Penerima
            Pinjaman.
          </li>
          <li>
            Invoice Financing adalah metode kegiatan pendanaan yang diberikan
            oleh Pemberi Pinjaman, dilakukan dengan cara menjaminkan tagihan
            (piutang dagang) yang sedang berjalan sebagai sumber pembayaran
            pinjaman dari Penerima Pinjaman.
          </li>
          <li>
            Pemberi Pinjaman adalah pihak yang bekerjasama dengan Taralite dan
            bersedia untuk memberikan pendanaan pada program OVO Modal Usaha
            ini, kepada Penerima Pinjaman.
          </li>
          <li>
            Penerima Pinjaman adalah suatu badan hukum atau badan usaha yang
            bertindak sebagai (a) penyedia platform atau (b) penyedia jasa, yang
            melakukan kegiatan, dimana atas jasanya menerima pembayaran oleh
            Prinsipal atas piutang dagangnya.
          </li>
          <li>
            Prinsipal adalah suatu badan hukum atau badan usaha yang menggunakan
            jasa Penerima Pinjaman, dengan timbal balik melakukan pembayaran di
            rekening yang dituju.
          </li>
          <li>
            Pinjaman adalah pendanaan dalam bentuk uang senilai tertentu yang
            tersedia dalam program OVO Modal Usaha yang diberikan kepada
            Penerima Pinjaman melalui sistem teknologi informasi yang berasal
            dari Pemberi Pinjaman yang dialokasikan dan disalurkan satu kali
            (one time loan) oleh Taralite.
          </li>
          <li>
            Plafon adalah sejumlah limit Pinjaman yang dialokasikan oleh Pemberi
            Pinjaman, melalui Taralite kepada Penerima Pinjaman, berdasarkan
            hasil penilaian dan kelayakan Penerima Pinjaman yang dilakukan oleh
            Taralite.
          </li>
          <li>
            Biaya Layanan adalah biaya administrasi yang dibebankan oleh
            Taralite kepada Penerima Pinjaman di muka saat menggunakan OVO Modal
            Usaha sebagai metode pembayaran atas Pinjaman.
          </li>
          <li>
            Bunga Pinjaman adalah bunga yang dikenakan kepada Penerima Pinjaman
            atas Pinjaman yang diberikan.
          </li>
          <li>
            Bunga Tunggakan adalah bunga yang dikenakan kepada Penerima Pinjaman
            dalam hal terdapat tagihan tertunggak yang tidak dibayarkan penuh
            hingga batas waktu pembayaran (due date), yang dihitung setelah
            melewati masa tenggang 3 (tiga) hari sejak jatuh tempo.
          </li>
          <li>
            Biaya Keterlambatan adalah biaya yang dibayarkan setelah melewati
            masa tenggang 3 (tiga) hari sejak jatuh tempo.
          </li>
          <li>
            Perjanjian Pemberian Pembayaran adalah perjanjian, baik secara
            tertulis atau dalam bentuk elektronik, yang dibuat dan disepakati
            antara Pemberi Pinjaman dan Penerima Pinjaman, yang mengatur antara
            lain hak dan kewajiban, syarat dan ketentuan dan mekanisme serta
            prosedur pengembalian atau pembayaran Plafon.
          </li>
          <li>
            Ketentuan Situs adalah Syarat dan Ketentuan Situs/Aplikasi,
            Kebijakan Privasi, Syarat dan Ketentuan ini dan setiap Syarat dan
            Ketentuan lain yang dapat berlaku untuk atau sehubungan dengan
            Penggunaan Situs/Aplikasi dan seluruh fitur yang terdapat di
            dalamnya.
          </li>
          <li>
            Syarat dan Ketentuan adalah Syarat dan Ketentuan OVO Modal Usaha
            ini.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Pengajuan Pinjaman OVO Modal Usaha
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Kriteria Pengajuan Awal Bagi Penerima Pinjaman: Bahwa, untuk dapat
            melakukan pengajuan pinjaman dengan tujuan untuk diterima dan diakui
            sebagai Penerima Pinjaman dalam OVO Modal Usaha ini adalah:
            <ol className="list-[lower-alpha] ml-4">
              <li>
                Berbentuk badan hukum atau badan usaha (Perseroan Terbatas “PT”
                atau Commanditaire Vennootschap “CV”), dan berkedudukan di
                Republik Indonesia;
              </li>
              <li>
                Memiliki Prinsipal yang mumpuni dan dikenal dalam bisnis, dan
                merupakan salah satu rekanan Taralite dalam program OVO Modal
                Usaha;
              </li>
              <li>Usia perusahaan paling sedikit 2 (dua) tahun;</li>
              <li>
                Memiliki hubungan kerja dengan Prinsipal di atas selama paling
                sedikit 6 (enam) bulan;
              </li>
              <li>
                Telah mengeluarkan invoice / tagihan kepada Prinsipal setidaknya
                3 (tiga) kali;
              </li>
              <li>Memiliki Prinsipal;</li>
              <li>
                Memiliki nilai invoice / tagihan yang akan dijadikan underlying
                transaksi minimal senilai Rp50.000.000,00 (lima puluh juta
                Rupiah) dan maksimal senilai Rp2.000.000.000,00 (dua miliar
                Rupiah)
              </li>
            </ol>
          </li>
          <li>
            Calon Penerima Pinjaman yang mengajukan Pinjaman wajib menyerahkan
            dokumen-dokumen berikut ini kepada Taralite, sebagai proses
            verifikasi dan penilaian.
            <ol className="list-[lower-alpha] ml-4">
              <li>KTP jajaran direksi</li>
              <li>NPWP jajaran direksi</li>
              <li>NPWP perusahaan</li>
              <li>
                Akta pendirian perusahaan, dilengkapi dengan lembar pengesahan
                AHU
              </li>
              <li>
                Akta terbaru perusahaan, dilengkapi dengan lembar pengesahan AHU
              </li>
              <li>SIUP</li>
              <li>TDP atau NIB perusahaan</li>
              <li>Surat Keterangan Domisili atau NIB perusahaan</li>
              <li>Copy tagihan yang akan dijadikan underlying pinjaman</li>
              <li>Copy Purchase Order</li>
              <li>
                Copy Berita Acara Serah Terima (BAST) atau bukti penyerahan
                lainnya yang disetujui
              </li>
              <li>
                Laporan Keuangan 2 (dua) tahun terakhir (In-House atau Audited)
              </li>
              <li>Laporan Keuangan Triwulanan terbaru</li>
              <li>
                Rekening Koran 6 bulan terakhir dari seluruh bank di mana Calon
                Peminjam memiliki rekening
              </li>
              <li>Surat mengetahui dari Prinsipal (khusus untuk vendor OVO)</li>
              <li>Rincian Aset Lancar</li>
              <li>Rincian Kewajiban Lancar</li>
            </ol>
          </li>
          <li>
            Proses verifikasi dan penilaian akan dilakukan oleh Taralite dalam
            jangka waktu maksimum 2x24 jam (hari kerja).
          </li>
          <li>
            Calon Penerima Pinjaman menyetujui dan memberikan wewenang kepada
            Taralite untuk melakukan pemeriksaan dokumen tesebut.
          </li>
          <li>
            Pemberian Plafon dan/atau penolakan merupakan sepenuhnya hak
            Taralite. Calon Penerima Pinjaman akan menghormati keputusan dari
            Taralite.
          </li>
          <li>
            Calon Penerima Pinjaman menyetujui bahwa informasi yang didaftarkan
            pada saat pengajuan aplikasi merupakan informasi yang dapat
            digunakan dalam menghubungi dan/atau melakukan verifikasi terhadap
            pengguna; termasuk namun tidak terbatas pada informasi mengenai
            kontak darurat Penerima Pinjaman, oleh karena itu Penerima Pinjaman
            menyetujui Taralite untuk menghubungi kontak darurat tersebut dalam
            hal tidak dapatnya dihubungi Penerima Pinjaman dalam hal apapun.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Penentuan Plafon Pinjaman
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Plafon yang dapat diberikan oleh Pemberi Pinjaman dan disetujui oleh
            Taralite dalam program OVO Modal Usaha ini adalah paling banyak 90%
            (sembilan puluh persen) dari nilai piutang dagang Penerima Pinjaman
            pada Prinsipal.
          </li>
          <li>
            Penilaian dan persetujuan Plafon adalah hak mutlak Taralite yang
            diberikan berdasarkan hasil penilaian dan kelayakan atas Penerima
            Pinjaman. Penerima Pinjaman menerima dan menghormati keputusan
            tersebut.
          </li>
          <li>
            Taralite memiliki hak mutlak untuk menentukan piutang dagang milik
            Penerima Pinjaman mana saja yang memenuhi kriteria dalam Invoice
            Financing ini.
          </li>
          <li>
            Plafon Pinjaman akan ditentukan dan disebutkan dalam Perjanjian
            Pemberian Pinjaman yang nantinya akan ditandatangani oleh para pihak
            yang berwenang.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Perjanjian Pemberian Pinjaman
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Perjanjian Pemberian Pinjaman adalah dokumen yang akan
            ditandatangani oleh Pemberi Pinjaman, Taralite, dan Penerima
            Pinjaman sebagai dasar pemberian Plafon yang disetujui.
          </li>
          <li>
            Perjanjian Pemberian Pinjaman antara lain berisi Biaya Layanan,
            Bunga Pinjaman, Bunga Tunggakan, dan Biaya Keterlambatan, dan
            ketentuan-ketentuan spesifik lainnya yang telah disepakati oleh para
            pihak.
          </li>
          <li>
            Taralite berhak melakukan perubahan atau penyesuaian pada Biaya
            Layanan, Bunga Pinjaman, Bunga Tunggakan dan Biaya Keterlambatan,
            dengan pemberitahuan terlebih dahulu kepada Penerima Pinjaman.
          </li>
          <li>
            Dalam menandatangani Perjanjian Pemberian Pinjaman, Pemberi Pinjaman
            dan/atau Taralite dapat meminta penjaminan sebagai sumber pelunasan
            bagi pembayaran Pinjaman dan biaya-biaya lain yang timbul yang
            diberikan oleh Penerima Pinjaman dan/atau pihak ketiga pemberi
            pinjaman, dalam bentuk antara lain: gadai, fidusia, penjaminan
            perseorangan/perusahaan, hak tanggungan, dan sebagainya.
          </li>
          <li>
            Segala biaya dan prosedur pengikatan perjanjian penjaminan yang
            diberikan oleh Penerima Pinjaman dan/atau pihak ketiga pemberi
            penjaminan sepenuhnya menjadi tanggung jawab Penerima Pinjaman.
          </li>
          <li>
            Jangka waktu Perjanjian Pemberian Pinjaman adalah paling lama 60
            (enam puluh) hari kalender.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Pembayaran Pinjaman
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Penerima Pinjaman wajib melakukan pembayaran Pinjaman secara tepat
            waktu, dengan syarat dan ketentuan yang terdapat pada Perjanjian
            Pemberian Pinjaman.
          </li>
          <li>
            Piutang dagang yang dimiliki oleh Penerima Pinjaman atas Prinsipal
            secara seluruhnya wajib digunakan untuk pembayaran dan pelunasan
            Pinjaman secara tepat waktu.
          </li>
          <li>
            Pembayaran Pinjaman dapat dilakukan dengan menggunakan metode
            pembayaran yang diatur dalam Perjanjian Pemberian Pinjaman.
          </li>
        </ol>
      </div>

      <h2 className="text-lg text-pepper-darker font-bold mt-4">
        Ketentuan Lain-Lain
      </h2>
      <div className="flex w-full mt-3">
        <ol className="list-decimal ml-4 text-sm text-justify">
          <li>
            Penerima Pinjaman telah membaca, memahami dan menyetujui atas
            seluruh isi Syarat dan Ketentuan ini.
          </li>
          <li>
            Penerima Pinjaman memahami dan menyetujui semua risiko yang mungkin
            timbul dari segala penggunaan dan pelaksanaan program OVO Modal
            Usaha.
          </li>
          <li>
            Penerima Pinjaman selanjutnya memahami bahwa Taralite dalam
            memberikan layanan pada situs atau platform ini adalah sebagai
            penghubung antara Pemberi Pinjaman dan Penerima Pinjaman.
          </li>
          <li>
            Taralite dengan ini menyatakan bahwa setiap isi yang terkandung
            dalam Syarat dan Ketentuan ini adalah sebagai informasi atau
            gambaran umum mengenai produk OVO Modal Usaha. Apa yang terkandung
            dalam Syarat dan Ketentuan ini tidak ditujukan sebagai suatu nasihat
            profesional dalam hal pemberian suatu Pinjaman.
          </li>
          <li>
            Untuk setiap pertanyaan dan/atau permasalahan mengenai Syarat dan
            Ketentuan ini, Penerima Pinjaman dan/atau calon Penerima Pinjaman
            dapat menghubungi contact center Taralite pada
            <a
              className="text-pandan-dark"
              target="_blank"
              rel="noreferrer"
              href="mailto:cs-ovomodalusaha@taralite.com
              "
            >
              {" "}
              cs-ovomodalusaha@taralite.com
            </a>
            .{" "}
          </li>
          <li>
            Taralite dapat dan berhak untuk, dari waktu ke waktu, melakukan
            perubahan, penambahan, dan/atau modifikasi atas seluruh atau
            sebagian dari isi Syarat dan Ketentuan ini dengan melakukan
            pemberitahuan terlebih dahulu, sesuai dengan ketentuan dan peraturan
            yang berlaku.
          </li>
        </ol>
      </div>
    </>
  );
};

export default Content;
