import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


// Action
import { invoiceActions } from 'features/invoice.slice';
import { setLoading } from 'features/loading.slice';

// Component
import InvoiceCard from './Invoice/InvoiceCard';
import Pagination from 'components/Pagination';
import Error from 'components/Card/Error';

// Helpers
import { ceTeamIllustration } from 'config/images';
const LIMIT_PER_PAGE = 10;

const SubmittedInvoice = () => {
  const [pagination, setPagination] = useState({
    limit: LIMIT_PER_PAGE || 10,
    offset: 0,
  });
  const dispatch = useDispatch();
  const { getSubmittedInvoice } = invoiceActions;
  const { submitted: { invoices, total, error }, loading } = useSelector((state) => state.invoice);
  const { companySelected } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getSubmittedInvoice({
      ...(companySelected && { bc_id: companySelected }),
      ...pagination
    }));
  }, [pagination, companySelected]);

  dispatch(setLoading(loading));

  const handlePagination = (offset) => {
    setPagination({ ...pagination, offset });
  };

  const renderInvoice = (invoices) => {
    if (invoices.length === 0) {
      return (
        <Error
          title="You don’t have submitted invoices yet"
          subTitle={`Submit through the “Submit Invoice” button on top`}
          imgIllustation={ceTeamIllustration}
        />
      );
    }
    return invoices.map((invoice, key) => <InvoiceCard invoice={invoice} key={key} />);
  };
  return (
    <>
      {!error && renderInvoice(invoices)}
      {!error && invoices.length > 0 && (
        <div className="mt-8 flex justify-end">
          <Pagination
            itemsPerPage={LIMIT_PER_PAGE}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            totalData={total}
            onChange={handlePagination}
          />
        </div>
      )}
      {error && <Error title={error} subTitle={`Please contact the administrator`} />}
    </>
  );
};

export default SubmittedInvoice;
