import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loanActions } from "features/loan.slice";
import { setLoading } from "features/loading.slice";

import LoanItem from "./LoanItem";
import Pagination from "components/Pagination";
import Error from "components/Card/Error";
import LoanDetail from "./Detail";

import { CONSTANT } from "config/common";

const Loan = ({ state }) => {
  const dispatch = useDispatch()
  const { loans: { total_records, data, error }, loading } = useSelector((state) => state.loan)
  const { companySelected } = useSelector((state) => state.company)

  const { getLoans } = loanActions;
  const [pagination, setPagination] = useState({
    limit: CONSTANT.LIMIT_PER_PAGE || 10,
    offset: 0
  })
  const [ showDetail, setShowDetail ] = useState(false)

  useEffect(() => {
    const payload = {
      ...(companySelected && { bc_id: companySelected }),
      ...pagination,
      state: state || ""
    }
    dispatch(getLoans(payload))

  }, [pagination, state, companySelected])

  dispatch(setLoading(loading));

  const handlePagination = (page) => {
    setPagination({ ...pagination, offset: page })
  }

  return (
    <>
      {error && <Error title={error || "Something went wrong"} subTitle={`Please contact the administrator`} /> }
      <div className="mt-8 relative">
        {
          data &&
          data.map((item, index) => (
            <div key={index} className="mb-8">
              <LoanItem loan={item} key={index} clickDetail={setShowDetail} />
            </div>
          ))
        }
      </div>
      {!error && data.length > 0 && (
        <div className="mt-8 flex justify-end">
          <Pagination
            itemsPerPage={CONSTANT.LIMIT_PER_PAGE}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            totalData={total_records}
            onChange={handlePagination}
          />
        </div>
      )}
      {showDetail && <LoanDetail showDetail={showDetail} setShowDetail={setShowDetail} />}
    </>
  )
}

export default Loan;
