import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/Card";
import Chip from "components/Chips";
import Select from "components/Select";
import { setSelectedCompany } from "features/company.slice";
import { currency } from "helpers/formatter";

const UCCard = ({ limitInformation, listOfBorrower, onChange }) => {
  const dispatch = useDispatch();
  const { borrower, underlying } = limitInformation;
  const { companySelected } = useSelector((state) => state.company);

  const handleOnChange = ({ value }) => {
    dispatch(setSelectedCompany(value))
    onChange(value)
  }
  return (
    <>
      <div className="mb-8">
        <Card attributes={{ bgOn: true }} modifier={{ loading: false }}>
          <div className="grid grid-cols-8 gap-4">
            <div className="flex flex-col border-r-[1px] col-span-2">
              <div className="flex items-center">
                <p className="text-lg font-bold text-shallot-darkest mr-2">
                  OVO Modal Usaha
                </p>
                <Chip type="success" label="Active" />
              </div>
              <p className="text-sm font-normal text-pepper-dark">
                Onboarded: {moment(underlying.on_boarded_date).format("MMMM Do, YYYY") || "N/A"}
              </p>
            </div>
            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">UC Total Limit</p>
              <p className="text-xs font-bold text-pepper-dark">
                {currency(underlying.uc_total_limit) || "N/A"}
              </p>
            </div>
            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Total Assigned Limit</p>
              <p className="text-xs font-bold text-pepper-dark">
                {currency(underlying.uc_assigned_limit) || "N/A"}
              </p>
            </div>
            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Available Limit</p>
              <p className="text-xs font-bold text-pepper-dark">
                {currency(underlying.available_limit) || "N/A"}
              </p>
            </div>
            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Total Active Loans</p>
              <p className="text-xs font-bold text-pepper-dark">
                {underlying.total_active || "N/A"}
              </p>
            </div>
            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Total Remaining O/S</p>
              <p className="text-xs font-bold text-pepper-dark">
                {currency(underlying.total_due) || "N/A"}
              </p>
            </div>
            <div className="flex flex-col py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Lead Gen Fee</p>
              <p className="text-xs font-bold text-pepper-dark">
                {currency(underlying.lead_gen_fee) || "N/A"}
              </p>
            </div>
          </div>
        </Card>
      </div>
      <div className="mb-8">
        <Card attributes={{ bgOn: false }} modifier={{ loading: false }}>
          <div className="grid grid-cols-7 gap-4">
            <div className="flex flex-col border-r-[1px] col-span-2 pr-4">
              <span className="text-xs font-bold text-pandan-dark mb-2">Select Borrower:</span>
              <Select defaultValue={``} onChange={handleOnChange}>
                <Select.Option key={-1} value="">Select</Select.Option>
                {listOfBorrower.length > 0 && listOfBorrower.map((item, key) => (
                  <Select.Option key={key} value={item.id}>{item.legal_corporate_name}</Select.Option>
                ))}
              </Select>
            </div>
            {
              companySelected && (
                <>
                  <div className="flex flex-col border-r-[1px] py-1 space-y-2">
                    <p className="text-xs text-pepper-dark">Borrower Total Limit</p>
                    <p className="text-xs font-bold text-pepper-dark">
                      {currency(borrower.bc_total_limit) || "N/A"}
                    </p>
                  </div>
                  <div className="flex flex-col border-r-[1px] py-1 space-y-2">
                    <p className="text-xs text-pepper-dark">Available Limit</p>
                    <p className="text-xs font-bold text-pepper-dark">
                      {currency(borrower.remaining_limit) || "N/A"}
                    </p>
                  </div>
                  <div className="flex flex-col border-r-[1px] py-1 space-y-2">
                    <p className="text-xs text-pepper-dark">Total Active Loans</p>
                    <p className="text-xs font-bold text-pepper-dark">
                      {borrower.total_active || "N/A"}
                    </p>
                  </div>
                  <div className="flex flex-col border-r-[1px] py-1 space-y-2">
                    <p className="text-xs text-pepper-dark">Total Remaining O/S</p>
                    <p className="text-xs font-bold text-pepper-dark">
                      {currency(borrower.total_due) || "N/A"}
                    </p>
                  </div>
                  <div className="flex flex-col py-1 space-y-2">
                    <p className="text-xs text-pepper-dark">Onboarded</p>
                    <p className="text-xs font-bold text-pepper-dark">
                      {moment(borrower.on_boarded_date).format("MMMM Do, YYYY") || "N/A"}
                    </p>
                  </div>
                </>
              )
            }
          </div>
        </Card>
      </div>
    </>
  )
}

export default UCCard;
