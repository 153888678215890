import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tab from "components/Tab";
import { useNavigate } from "react-router-dom";
import Loan from "./Loan";

import SubmittedInvoice from "./SubmittedInvoice";
import Application from "./Application";
import RejectedInvoice from "./RejectedInvoice";
import { getLimitInformation, getBorrowerCompany, getUCLimitInformation } from "features/company.slice";
import UCCard from "./UCCard";
import BCCard from "./BCCard";
import { COMPANY_TYPE } from "config/company";
import { leftArrowIcon } from "config/icons";
import { LOAN_STATUS } from "config/status";

const Detail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { companyId, limitInformation, borrower, companyType, companySelected } = useSelector((state) => state.company);
  useEffect(() => {
    if (companyType === COMPANY_TYPE.UNDERLYING_COMPANY) {
      dispatch(getBorrowerCompany())
    }
  }, [dispatch, companyType])

  useEffect(() => {
    if (companyType === COMPANY_TYPE.UNDERLYING_COMPANY) {
      companyId && dispatch(getUCLimitInformation(companyId))
    } else if (companyType === COMPANY_TYPE.BORROWER_COMPANY) {
      companyId && dispatch(getLimitInformation(companyId))
    }
  }, [companyId, dispatch, companyType])

  const handleOnChange = (value) => {
    if (value) {
      dispatch(getLimitInformation(value))
    }
  }

  const renderPortfolioTab = (companyType, companySelected) => {
    if (companyType === COMPANY_TYPE.UNDERLYING_COMPANY && !companySelected) {
      return null
    }
    return (
      <Tab>
        <div label="Submitted Invoice" tabId={1}>
          <SubmittedInvoice />
        </div>
        <div label="Application" tabId={2}>
          <Application />
        </div>
        <div label="Active Loans" tabId={3}>
          <Loan state={LOAN_STATUS.DISBURSED} />
        </div>
        <div label="Paid Loans" tabId={4}>
          <Loan state={LOAN_STATUS.CLOSED} />
        </div>
        <div label="Rejected Invoices" tabId={5}>
          <RejectedInvoice />
        </div>
      </Tab>
    )
  }

  return (
    <div className="max-w-6xl m-auto mt-12">
      <button className="flex mb-8" onClick={() => navigate("/")}>
        <img src={leftArrowIcon} alt="Arrow Left" />
        <span className="text-sm font-bold py-0.5">Back to Home</span>
      </button>
      <div className="mb-8">
        {
          companyType === COMPANY_TYPE.UNDERLYING_COMPANY ?
            <UCCard limitInformation={limitInformation} listOfBorrower={borrower} onChange={handleOnChange} /> :
            <BCCard limitInformation={limitInformation?.borrower} company={companyType} />
        }
      </div>
      {renderPortfolioTab(companyType, companySelected)}
    </div>
  );
};

export default Detail;
