const Chip = ({ type = "", label = "", className = "" }) => {
  const colorType = () => {
    switch (type) {
      case "success":
        return "bg-pandan-lightest text-pandan-dark";
      case "danger":
        return "bg-paprika-lightest text-paprika-dark";
      case "warning":
        return "bg-turmeric-lightest text-turmeric-darker";
      case "info":
        return "bg-taro-lightest text-taro-dark";
      default:
        return "bg-pepper-lighter";
    }
  };

  return (
    <div
      className={`px-2 cursor-default text-xs font-bold inline-block rounded-3xl min-w-[60px] text-center leading-5 ${colorType()} ${className}`}
    >
      {label}
    </div>
  );
};

export default Chip;
