import { Link, useLocation, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { authActions } from "features/auth.slice";

import Menu from "./Menu";
import Header from "./Header";
import Welcome from "./Welcome";
import UserDropdown from "./UserDropdown";

import "./MainPageLayout.css";

const taraliteLogo = require("assets/images/taralite.svg").default;

const MainPageLayout = ({ attributes, children }) => {
  const location = useLocation();
  const { pathname } = location;
  const firstStringPathname = pathname?.split("/")[1] || "";
  const isLoanApplication = pathname.includes("loan-application");

  // Get user name
  const { user } = useSelector((state) => state.auth);
  const { companyType } = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(authActions.signOut());
  };

  const getUsername = () => {
    return user?.username.split("@")[0] || "User";
  };

  const getUsernameInitial = () => {
    return getUsername().split("")[0].toUpperCase() || "U";
  };

  const getRoleName = () => {
    return companyType === "borrower_company" ? "Borrower" : "Underlying";
  };

  const isProductHighlighted = () => {
    return ["", "portfolio"].includes(firstStringPathname) || isLoanApplication;
  };

  const redirectToChangePasswordPage = () => {
    const GRIFFIN_URL = process.env.REACT_APP_GRIFFIN_HOST;
    const currentPage = window.location.href;

    window.location = `${GRIFFIN_URL}accounts/password_change/?next_page=${currentPage}`;
  };

  const { welcome } = attributes;

  return (
    <div className="flex flex-col min-h-screen">
      <Header>
        <Link to="/">
          <img src={taraliteLogo} alt="Taralite logo" />
        </Link>
        <Menu>
          <Link to="/">
            <Menu.Item modifier={{ active: isProductHighlighted() }}>Product Portfolio</Menu.Item>
          </Link>
          <Link to="/profile">
            <Menu.Item modifier={{ active: firstStringPathname === "profile" }}>Profile</Menu.Item>
          </Link>
        </Menu>
        <UserDropdown
          attributes={{
            user: {
              name: getUsername(),
              role: getRoleName(),
              initial: getUsernameInitial(),
            },
          }}
        >
          <div onClick={redirectToChangePasswordPage}>
            <UserDropdown.Item>Change password</UserDropdown.Item>
          </div>
          <UserDropdown.Item>
            <div onClick={signOut}>Logout</div>
          </UserDropdown.Item>
        </UserDropdown>
      </Header>
      <div className={`${welcome ? "main-bg " : ""} flex-1 mt-[72px] bg-pepper-lighter px-6`}>
        {welcome && <Welcome />}
        {children || <Outlet />}
      </div>
    </div>
  );
};

MainPageLayout.defaultProps = {
  attributes: {
    welcome: false,
  },
};

export default MainPageLayout;
