import { createSlice } from "@reduxjs/toolkit";

const name = "loading";
const initialState = {
  loading: false,
};
const reducers = {
  setLoading: (state, { payload }) => {
    state.loading = payload;
  },
};

export const loadingSlice = createSlice({ name, initialState, reducers });
export const { setLoading } = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
