import classNames from "classnames";

import Modal from ".";
import Button from "components/Button";

const AlertModal = ({ modifier, events }) => {
  const { show, heading, subheading, buttonText, imageSrc, imageAlt } = modifier;
  const { closeModal, handleConfirm } = events;

  const renderImage = () => {
    if (imageSrc) {
      return <img src={imageSrc} alt={imageAlt} className="flex-50" />;
    }
  };

  return (
    <Modal
      modifier={{
        show: show,
      }}
      events={{
        closeModal: closeModal,
      }}
    >
      <div className="flex">
        {renderImage()}
        <div
          className={`${classNames({
            "ml-4": imageSrc,
          })} flex flex-50 flex-col justify-center`}
        >
          <h3 className="font-bold text-lg text-center">{heading}</h3>
          <p className="text-center text-pepper-dark text-sm mt-2">
            {subheading}
          </p>

          <Button
            modifier={{ className: "mt-4" }}
            events={{
              onClick: handleConfirm,
            }}
          >
            {buttonText || 'Okay'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
