import { useEffect } from "react";
import "./Loader.css";

const Loader = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => (document.body.style.overflow = "");
  }, []);

  return (
    <div className="bg-black/40 fixed inset-0 z-50 flex justify-center items-center">
      <div className="bg-white p-10 rounded-xl spinner flex justify-center items-center">
        <span id="dots-1" className="w-4 h-4 bg-pandan-darker rounded-full inline-block mr-3" />
        <span id="dots-2" className="w-4 h-4 bg-pandan-darker rounded-full inline-block mr-3" />
        <span id="dots-3" className="w-4 h-4 bg-pandan-darker rounded-full inline-block mr-0" />
      </div>
    </div>
  );
};

export default Loader;
