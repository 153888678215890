/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { getCookie, setCookie } from "helpers/cookies";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./auth.slice";
import { getCompanyInformation } from "./company.slice";
import { setLoading } from "./loading.slice";

const RequireAuth = () => {
  const location = useLocation();
  const currentPath = location?.pathname;
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.company);
  const { getUser } = authActions;

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading]);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getCompanyInformation());
  }, []);

  useEffect(() => {
    setCookie("user_dashboard_url", currentPath);
  }, [currentPath]);

  const token = getCookie("auth_token");

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default RequireAuth;
