import { useRef } from "react";
import { fileIcon } from "config/icons";

const Upload = ({ onChange, hasFileName = false }) => {
  const fileRef = useRef(null);
  const handleChange = () => {
    onChange(fileRef.current.files[0].name);
  }
  return (
    <label
      className={`flex flex-row justify-center items-center py-[6px] px-5 text-center h-8 bg-primary-lightest rounded-3xl hover:cursor-pointer hover:bg-primary-lighter transition-all ${
        hasFileName ? "w-[78px]" : "w-[102px]"
      }`}
    >
      {hasFileName ? (
        <span className="text-xs font-bold text-pandan-dark flex justify-center items-center">Change</span>
      ) : (
        <>
          <img src={fileIcon} alt="file-icon" className="mr-2" />
          <span className="text-xs font-bold text-pandan-dark">Upload</span>
        </>
      )}

      <input type="file" ref={fileRef} className="hidden" onChange={handleChange} />
    </label>
  );
}

export default Upload;
