import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AUTH_API_URL } from "api";
import { removeCookie, setCookie } from "helpers/cookies";
import { getCookie } from "helpers/cookies";
import { getLogoutURL } from "helpers/auth";

const CLIENT_ID = process.env.REACT_APP_GRIFFIN_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_GRIFFIN_CLIENT_SECRET;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

const name = "auth"
const initialState = createInitialState()
const reducers = createReducers()
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

export const authSlice = createSlice({ name, initialState, reducers, extraReducers })

export const authActions = { ...authSlice.actions, ...extraActions }
export const authReducer = authSlice.reducer

function createInitialState() {
  return {
    user: null,
    token: null
  }
}

function createReducers() {
  return { signOut }
  function signOut(state) {
    state.user = null
    removeCookie("auth_token")
    removeCookie("user_dashboard_url")
    window.location.href = getLogoutURL();
  }
}

function createExtraActions() {
  return {
      getToken: getToken(),
      getUser: getUser()
  };

  function getToken() {
    return createAsyncThunk(
      `${name}/getToken`,
      async (code) => {
        const formData = new URLSearchParams();
        formData.append('client_id', CLIENT_ID)
        formData.append('client_secret', CLIENT_SECRET)
        formData.append('code', code)
        formData.append('redirect_uri', REDIRECT_URI)
        formData.append('grant_type', 'authorization_code')
        const response = await axios.post(AUTH_API_URL.TOKEN, formData)
        return response?.data
      }
    );
  }

  function getUser() {
    return createAsyncThunk(
      `${name}/getUser`,
      async () => {
        const headers = {
          headers: { Authorization: `Bearer ${getCookie("auth_token")}` }
        };
        const response = await axios.get(AUTH_API_URL.WHOAMI, headers)
        return response?.data
      }
    )
  }
}

function createExtraReducers() {
  return {
    ...getToken(),
    ...getUser(),
  }
  function getToken() {
    const { pending, fulfilled, rejected } = extraActions.getToken
    return {
      [pending]: state => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        const { access_token: token } = action.payload
        setCookie("auth_token", token, { maxAge: 3600 })
        state.token = token
      },
      [rejected]: (state, action) => {
        state.token = null
        state.error = action.error
      }
    }
  }

  function getUser() {
    const { pending, fulfilled, rejected } = extraActions.getUser
    return {
      [pending]: state => {
        state.error = null
      },
      [fulfilled]: (state, action) => {
        const { response } = action.payload
        state.user = response
      },
      [rejected]: (state, action) => {
        state.user = null
        state.error = action.error
      }
    }
  }
}
