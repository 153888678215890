import { useState } from 'react';
import classNames from "classnames";
import './Checkbox.css';

const Checkbox = ({ attributes, modifier, events }) => {
  const { disabled, labelClassName, checkBoxClassName, checkBoxContainerClassName } = modifier;
  const { label } = attributes;
  const { onCheck } = events;

  const [checked, setChecked] = useState(false);
  const handleCheck = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    onCheck(isChecked);
  }
  return (
    <label className={`flex justify-start items-center ${classNames(checkBoxContainerClassName)}`}>
      <div
        id="checkbox"
        className={`border-pepper-light
          ${disabled ? `bg-pepper-disabled` : ``}
          ${checked ? `bg-seasalt border-seasalt ring-2 ring-seasalt ring-opacity-30` : `bg-white`}
          border-2 rounded-md w-5 h-5 flex flex-shrink-0 justify-center items-center mr-4
          ${classNames(checkBoxClassName)}`}
      >
        <input
          type="checkbox"
          className="opacity-0 absolute disabled:bg-pepper-disabled"
          onChange={handleCheck}
          disabled={disabled}
        />
        <svg
          className="fill-current hidden w-2.5 h-2.5 text-green-500 pointer-events-none bg-seasalt"
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
            <path d="M1.70711 2.29289C1.31658 1.90237 0.683418 1.90237 0.292893 2.29289C-0.0976311 2.68342 -0.0976311 3.31658 0.292893 3.70711L2.29289 5.70711C2.68342 6.09763 3.31658 6.09763 3.70711 5.70711L7.70711 1.70711C8.09763 1.31658 8.09763 0.683418 7.70711 0.292893C7.31658 -0.0976311 6.68342 -0.0976311 6.29289 0.292893L3 3.58579L1.70711 2.29289Z" fill="white"/>
        </svg>
      </div>
      <div className={`select-none text-pepper-dark text-sm ${classNames(labelClassName)}`}>{label}</div>
    </label>
  )
}

Checkbox.defaultProps = {
  attributes: {
    label: "Checkbox"
  },
  modifier: {
    disabled: false,
    labelClassName: "",
    checkBoxClassName: "",
    checkBoxContainerClassName: ""
  },
  events: {
    onCheck: () => {}
  }
}

export default Checkbox;
