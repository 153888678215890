export const currency = (nominal) => {
  if (nominal == null) return "";
  return "IDR " + nominal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

export const removeLeadingZero = (value) => {
  if (!value) return "";
  return value.replace(/^0+/, "");
};

export const pluralCheck = (num, word) => {
  if (num <= 0) {
    return `${num} ${word}`
  }
  return `${num} ${word}(s)`
}

export const percentage = (num) => {
  return `${num}%`
}

export const currencyOrPercentage = (num, type) => {
  switch (type) {
    case "percentage":
      return percentage(num);
    case "flat":
      return currency(num);
    default:
      return num;
  }
};

export const maskingText = (text, limit = 5) => {
  const splitWord = text.split("");
  if (splitWord.length < 12) {
    return text;
  } else {
    const newWord = splitWord.splice(0, limit).join("");
    const fileExtension = text.split('.')[text.split('.').length - 1]
    return newWord + "..." + fileExtension;
  }
};
