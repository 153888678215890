import React from "react";

const List = ({ column = 1, children }) => {
  const validChild = React.Children.toArray(children).map((child) => React.isValidElement(child) && child)
  const itemLength = validChild.length;
  const row = Math.ceil(itemLength / column);
  return (
    <div>
      <table className="w-full border-separate [border-spacing:0_0.75rem]">
        <tbody>
          {Array.from({ length: row }, (_, trIdx) => (
            <tr key={trIdx}>
              {Array.from({ length: column }, (_, tdIdx) => (
                <td key={tdIdx} className={`p-4 rounded-[10px] w-1/3 ${(trIdx + tdIdx) % (2) ? "bg-white" : "bg-[#FBFBFB]"}`}>
                  {validChild[((trIdx+1) + (tdIdx*row) - 1)]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const Item = ({ label, children }) => {
  return (
    <>
      <div className="flex justify-between">
        <span className="text-left text-sm font-medium text-black w-1/2">{label}</span>
        <span className="text-right text-sm font-normal text-pepper-dark w-1/2">{children}</span>
      </div>
    </>
  )
}

List.Item = Item;
export default List;
