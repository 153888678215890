import axios from "axios";
import { getCookie } from "./cookies";
import { USER_DASHBOARD_API_URL } from 'api';
class FetchAPI {
  static headers() {
    const token = getCookie("auth_token") || ""
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  static config(anyParam) {
    return {
      headers: this.headers(),
      ...anyParam,
    };
  }

  static setConfig(anyParam) {
    return this.config({ params: anyParam });
  }

  static get(url, anyParam) {
    const config = this.setConfig(anyParam);
    return axios.get(`${USER_DASHBOARD_API_URL}/${url}`, config);
  }

  static post(url, payload) {
    const config = this.setConfig();
    return axios.post(
      `${USER_DASHBOARD_API_URL}/${url}`,
      payload,
      config
    );
  }

  static put(url, anyParam) {
    const config = this.setConfig(anyParam);
    return axios.put(`${USER_DASHBOARD_API_URL}/${url}`, config);
  }
}

export default FetchAPI;
