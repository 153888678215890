import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { routes, privateRoutes, homeMainRoutes } from "routes";
import { TOGGLE_KEY } from "config/common";
import MainPageLayout from "layouts/MainPageLayout";
import Wrapper from "components/Wrapper";

import store from "redux/store";
import RequireAuth from "features/RequireAuth";

const App = () => {
  const {
    userDashboardInvoiceSubmission20220912: invoiceSubmissionFlag,
    userDashboardDocumentUpload20220825: documentUploadFlag,
  } = useFlags();

  const pages = routes.map((route) => (
    <Route key={route.element} element={route.element} exact={route.exact} path={route.path} />
  ));

  const privateRoute = privateRoutes.map((route) => {
    if (route.isToggleRoute) {
      if (documentUploadFlag && route.toggleKey === TOGGLE_KEY.documentUpload) {
        return <Route key={route.element} element={route.element} exact={route.exact} path={route.path} />;
      }

      if (invoiceSubmissionFlag && route.toggleKey === TOGGLE_KEY.invoiceSubmission) {
        return <Route key={route.element} element={route.element} exact={route.exact} path={route.path} />;
      }
    } else {
      return <Route key={route.element} element={route.element} exact={route.exact} path={route.path} />;
    }

    return null;
  });

  const home = homeMainRoutes.map((route) => (
    <Route key={route.element} element={route.element} exact={route.exact} path={route.path} />
  ));

  return (
    <Provider store={store}>
      <Wrapper>
        <Router>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route element={<MainPageLayout attributes={{ welcome: true }} />}>{home}</Route>
              <Route element={<MainPageLayout attributes={{ welcome: false }} />}>{privateRoute}</Route>
            </Route>
            <Route>{pages}</Route>
          </Routes>
        </Router>
      </Wrapper>
    </Provider>
  );
};

export default App;
