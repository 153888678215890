import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAuthenticateURL } from "helpers/auth";
import { getCookie } from "helpers/cookies";


const Login = () => {
  const url = getAuthenticateURL()
  const navigate = useNavigate()
  useEffect(() => {
    const token = getCookie("auth_token")
    if (!token) {
      return window.location.href = url
    }
    navigate("/")
    
  }, [])

  return null
}

export default Login;