import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Helmet from "react-helmet";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>404 | Page Not Found</title>
      </Helmet>
      <div className="p-4 h-screen container flex justify-center items-center ml-auto mr-auto">
        <div>
          <img
            src="https://lending-assets.oss-ap-southeast-5.aliyuncs.com/webview/illustration/error.svg"
            alt="Page Not Found"
            className="ml-auto mr-auto mb-4"
          />
          <h1 className="text-3xl font-bold text-center">404: Page Not Found</h1>
          <p className="mt-2 text-center text-pepper-dark text-sm">
            The page you are looking for might have been removed <br />
            or temporarily unavailable.
          </p>
          <div className="w-1/2 ml-auto mr-auto mt-10">
            <Button
              events={{
                onClick: () => navigate("/"),
              }}
            >
              Back to Home
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
