import { useState, useEffect } from "react";
import classNames from "classnames";

const Accordion = ({ attributes = {}, modifier = {}, children = null }) => {
  const { title } = attributes;
  const { disabled, className, defaultOpen } = modifier;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(defaultOpen);
    }
  }, []);

  return (
    <div
      className={classNames(className, "rounded-xl bg-white", {
        "opacity-50": disabled,
      })}
    >
      <div
        className="flex items-center justify-between p-6 h-[72px] select-none cursor-pointer"
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <div>
          <h4 className="w-full text-base font-bold">{title}</h4>
        </div>
        <img
          className={`w-4 h-4 transition-all ${
            isOpen ? "-rotate-180" : "rotate-0"
          }`}
          src="https://ovo-fs-frontend-dev.oss-ap-southeast-5.aliyuncs.com/Font-awesom-icon/chevron-up.svg"
          alt={`${isOpen ? "Arrow Up" : "Arrow Down"}`}
        />
      </div>
      <div
        className={`overflow-hidden transition-all ${
          isOpen ? "max-h-[1000rem]" : "max-h-0"
        }`}
      >
        <div className="p-6 pt-0">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
