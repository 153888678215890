/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";

import { currency, removeLeadingZero } from "helpers/formatter";
import { getInvoiceConfiguration, createInvoice, editInvoice, deleteInvoice } from "features/add-invoice.slice";
import { setLoading } from "features/loading.slice";
import {
  MAX_INVOICE_FORM_LIMIT,
  ALL_FIELDS,
  ERROR_API_MESSAGES,
  DEFAULT_FORM_FIELDS,
  ERROR_VALIDATION_MESSAGES,
} from "config/add-invoice";

import InputField from "components/InputField";
import LoanApplicationLayout from "layouts/LoanApplicationLayout";
import Button from "components/Button";
import Uploader from "components/Uploader";
import DocumentInformation from "./DocumentInformation";

const InvoiceForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userDashboardDocumentUpload20220825 } = useFlags();

  const { invoice, loading } = useSelector((state) => state.addInvoice);
  const { invoices: invoiceList } = invoice;
  const {
    late_interest_rate_per_1_late_day,
    interest_rate_per_30_days,
    admin_fee,
    late_admin_fee,
    admin_fee_type,
    late_admin_fee_mode,
  } = invoice.pricing;

  const [formFields, setFormFields] = useState([DEFAULT_FORM_FIELDS]);

  const [errorsAPI, setErrorsAPI] = useState({
    proposed_loan_amount: [],
    invoice_number: [],
  });

  const [errors, setErrors] = useState({
    proposed_loan_amount: [],
    invoice_number: [],
    invoice_amount: [],
    issued_date: [],
    tenure: [],
  });

  useEffect(() => {
    dispatch(getInvoiceConfiguration());
  }, []);

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (invoiceList.length > 0) {
      setFormFields(invoiceList);
    } else if (invoiceList.length === 0) {
      setFormFields([DEFAULT_FORM_FIELDS]);
    }
  }, [invoiceList]);

  const addFormFields = () => {
    setFormFields([...formFields, DEFAULT_FORM_FIELDS]);
  };

  const removeFormFields = async (index) => {
    clearError(index);

    const fields = [...formFields];
    fields.splice(index, 1);

    const { id: invoiceId } = formFields[index];

    if (invoiceId) {
      const response = await dispatch(deleteInvoice(invoiceId));
      if (response.meta.requestStatus === "fulfilled") setFormFields(fields);
    } else {
      setFormFields(fields);
    }
  };

  const clearError = (index) => {
    ALL_FIELDS.map((field) => {
      const listErrorIndex = [...errors[field]];
      const targetIndex = listErrorIndex.indexOf(index);
      listErrorIndex.splice(targetIndex, 1);

      return setErrors((prevState) => ({ ...prevState, [field]: listErrorIndex }));
    });
  };

  const parseValue = (value) => {
    return parseInt(value.replace(/\D/g, ""), 10);
  };

  const handleFormChange = (index, event) => {
    const { name, value } = event.target;
    const data = JSON.parse(JSON.stringify(formFields));

    checkEmptyField(index, event);

    if (["proposed_loan_amount", "invoice_amount"].includes(name)) {
      data[index][name] = parseValue(value);
    } else if (name === "tenure") {
      if (value > 180) {
        data[index][name] = 180;
      } else if (value < 0) {
        data[index][name] = 1;
      } else {
        data[index][name] = removeLeadingZero(value);
      }
    } else {
      data[index][name] = value;
    }

    setFormFields(data);
  };

  const checkEmptyField = (index, event) => {
    const { name, value } = event.target;

    if (!value || value.split(" ")[1] === "") {
      if (!errors[name].includes(index))
        setErrors((prevState) => ({ ...prevState, [name]: [...prevState[name], index] }));
    } else {
      const listErrorIndex = [...errors[name]];
      const targetIndex = listErrorIndex.indexOf(index);
      listErrorIndex.splice(targetIndex, 1);

      setErrors((prevState) => ({ ...prevState, [name]: listErrorIndex }));
    }
  };

  const validateAllFields = () => {
    formFields.map((item, index) => {
      return ALL_FIELDS.map((field) => {
        if (!item[field]) {
          return setErrors((prevState) => {
            if (!prevState[field].includes(index)) return { ...prevState, [field]: [...prevState[field], index] };

            return { ...prevState };
          });
        } else {
          const listErrorIndex = [...errors[field]];
          const targetIndex = listErrorIndex.indexOf(index);
          listErrorIndex.splice(targetIndex, 1);

          return setErrors((prevState) => ({ ...prevState, [field]: listErrorIndex }));
        }
      });
    });
  };

  const checkResponseFromAPI = (index, response) => {
    const {
      meta: { requestStatus },
      payload: { response_message, response_code },
    } = response;

    if (requestStatus === "rejected") {
      if (response_message === "insufficient loan's remaining limit") {
        const { proposed_loan_amount } = errorsAPI;

        if (!proposed_loan_amount.includes(index)) {
          const listErrorIndex = [...proposed_loan_amount, index];
          setErrorsAPI((prevState) => ({ ...prevState, proposed_loan_amount: listErrorIndex }));
        }
      } else if (response_code === "OV00003") {
        const { invoice_number } = errorsAPI;

        if (!invoice_number.includes(index)) {
          const listErrorIndex = [...invoice_number, index];
          setErrorsAPI((prevState) => ({ ...prevState, invoice_number: listErrorIndex }));
        }
      }
    } else {
      const { proposed_loan_amount, invoice_number } = errorsAPI;

      const listLoanAmountErrorIndex = [...proposed_loan_amount];
      const targetLoanAmountIndex = listLoanAmountErrorIndex.indexOf(index);
      listLoanAmountErrorIndex.splice(targetLoanAmountIndex, 1);

      const listInvoiceErrorIndex = [...invoice_number];
      const targetInvoiceIndex = listInvoiceErrorIndex.indexOf(index);
      listInvoiceErrorIndex.splice(targetInvoiceIndex, 1);

      setErrorsAPI((prevState) => ({
        ...prevState,
        proposed_loan_amount: listLoanAmountErrorIndex,
        invoice_number: listInvoiceErrorIndex,
      }));
    }
  };

  const callUpdateInvoiceAPI = async (index, event) => {
    const currentField = formFields[index];
    const { invoice_number, invoice_amount, tenure, proposed_loan_amount, issued_date, id: invoiceId } = currentField;

    const payload = { invoice_number, invoice_amount, tenure, proposed_loan_amount, issued_date };
    const args = { invoiceId, payload };

    checkEmptyField(index, event);

    if (invoiceId) {
      const response = await dispatch(editInvoice(args));
      checkResponseFromAPI(index, response);
    } else {
      const response = await dispatch(createInvoice(payload));
      checkResponseFromAPI(index, response);
    }
  };

  const isDateAfterToday = (date) => {
    return moment(date).isAfter(new Date());
  };

  const isAllFormValid = () => {
    const hasErrorAPI = errorsAPI.proposed_loan_amount.length > 0 || errorsAPI.invoice_number.length > 0;
    const allFormFilled = formFields.every(
      (f) => f.invoice_amount && f.invoice_number && f.issued_date && f.proposed_loan_amount && f.tenure
    );
    const isProposedAmountLessThanInvoiceAmount = formFields.every((f) => f.proposed_loan_amount <= f.invoice_amount);
    const isIssuedDateMaxToday = formFields.every((f) => !isDateAfterToday(f.issued_date));

    return !hasErrorAPI && allFormFilled && isProposedAmountLessThanInvoiceAmount && isIssuedDateMaxToday;
  };

  const handleSubmit = () => {
    validateAllFields();

    if (isAllFormValid()) {
      if (userDashboardDocumentUpload20220825) {
        navigate("/loan-application/document");
      } else {
        navigate("/loan-application/terms-conditions");
      }
    }
  };

  const getFeeByType = (type, config) => {
    const fees = {
      admin: {
        percentage: `${admin_fee}%`,
        flat: currency(admin_fee),
      },
      lateAdmin: {
        percentage: `${late_admin_fee}%`,
        flat: currency(late_admin_fee),
      },
    };

    return fees[type]?.[config] || "-";
  };

  const renderFormFields = () => {
    const formLength = formFields.length;

    return formFields.map((input, index) => (
      <section key={index}>
        <div className="flex justify-between items-center mt-10 mb-6">
          <h2 className="font-bold text-pepper-darker text-2xl">Invoice {formLength > 1 ? index + 1 : ""} Details</h2>
          {formLength > 1 && (
            <span className="text-3xl cursor-pointer" onClick={() => removeFormFields(index)}>
              &#215;
            </span>
          )}
        </div>
        <div>
          {userDashboardDocumentUpload20220825 ? (
            <>
              <div className="grid grid-cols-2 gap-6 my-6">
                <Uploader title="Invoice" fileType="(.pdf / .jpg / .jpeg / .png)" />
                <Uploader title="Invoice Receipts" fileType="(.pdf / .jpg / .jpeg / .png)" />
              </div>
              <div className="grid grid-cols-2 gap-6 my-6">
                <DocumentInformation title="Others" />
              </div>
            </>
          ) : null}

          <InputField
            attributes={{
              type: "text",
              label: "Invoice Number",
              id: "invoiceNumber",
              name: "invoice_number",
              placeholder: "Insert invoice number",
              value: input.invoice_number,
            }}
            modifier={{
              error:
                (errors.invoice_number.includes(index) && "Enter invoice number") ||
                (errorsAPI.invoice_number.includes(index) && ERROR_API_MESSAGES.invoice_number),
            }}
            events={{
              onChange: (event) => handleFormChange(index, event),
              onBlur: (event) => callUpdateInvoiceAPI(index, event),
            }}
          />
          <InputField
            attributes={{
              type: "tel",
              label: "Invoice Value",
              id: "invoiceValue",
              name: "invoice_amount",
              placeholder: "Insert invoice value",
              value: currency(input.invoice_amount),
            }}
            modifier={{
              error: errors.invoice_amount.includes(index) && "Enter invoice value",
            }}
            events={{
              onChange: (event) => handleFormChange(index, event),
              onBlur: (event) => callUpdateInvoiceAPI(index, event),
            }}
          />
          <InputField
            attributes={{
              type: "tel",
              label: "Proposed Loan Amount",
              id: "proposedLoanAmount",
              name: "proposed_loan_amount",
              placeholder: "Insert proposed loan amount",
              value: currency(input.proposed_loan_amount),
            }}
            modifier={{
              error:
                (errors.proposed_loan_amount.includes(index) && "Enter proposed loan amount") ||
                (errorsAPI.proposed_loan_amount.includes(index) && ERROR_API_MESSAGES.proposed_loan_amount) ||
                (input.proposed_loan_amount &&
                  input.proposed_loan_amount > input.invoice_amount &&
                  ERROR_VALIDATION_MESSAGES.proposed_loan_amount),
            }}
            events={{
              onChange: (event) => handleFormChange(index, event),
              onBlur: (event) => callUpdateInvoiceAPI(index, event),
            }}
          />
          <InputField
            attributes={{
              type: "date",
              label: "Invoice Issued Date",
              id: "invoiceIssuedDate",
              name: "issued_date",
              value: input.issued_date,
            }}
            modifier={{
              error:
                (errors.issued_date.includes(index) && "Enter invoice issued date") ||
                (isDateAfterToday(input.issued_date) && ERROR_VALIDATION_MESSAGES.issued_date),
            }}
            events={{
              onChange: (event) => handleFormChange(index, event),
              onBlur: (event) => callUpdateInvoiceAPI(index, event),
            }}
          />
          <InputField
            attributes={{
              type: "number",
              label: "Loan Tenure",
              id: "loanTenure",
              name: "tenure",
              min: 0,
              max: 180,
              placeholder: "Insert loan tenure days",
              value: input.tenure || "",
            }}
            events={{
              onChange: (event) => handleFormChange(index, event),
              onBlur: (event) => callUpdateInvoiceAPI(index, event),
            }}
            modifier={{
              sublabel: "Maximum loan tenure is 180 days.",
              error: errors.tenure.includes(index) && "Enter loan tenure",
            }}
          />
        </div>
      </section>
    ));
  };

  const InfoCard = ({ label, value }) => {
    const isInvalidValue = !value || value === "0%";

    return (
      <div className="p-4 flex justify-between bg-[#FBFBFB] rounded-lg text-sm mr-3 w-1/2">
        <div className="font-medium">{label}</div>
        {loading && isInvalidValue ? (
          <div className="h-2 mt-2 bg-pepper-dark rounded w-20 animate-pulse" />
        ) : (
          <div className="text-pepper-dark">{value}</div>
        )}
      </div>
    );
  };

  return (
    <LoanApplicationLayout progressBarStep={1}>
      <h1 className="text-3xl font-bold text-pepper-darker">OVO ModalUsaha Loan Application</h1>
      <label className="inline-block font-medium text-sm mt-3 text-pepper-darker">
        This section is only eligible for OVO ModalUsaha registered borrower company.
      </label>

      <h2 className="font-bold text-pepper-darker text-2xl mt-10">Pricing Configuration</h2>
      <div className="flex w-full mt-3">
        <InfoCard label="Interest Rate per 30 days" value={`${interest_rate_per_30_days}%`} />
        <InfoCard label="Late Interest Rate per 1 Late Day" value={`${late_interest_rate_per_1_late_day}%`} />
      </div>
      <div className="flex w-full mt-3">
        <InfoCard label="Admin Fee" value={getFeeByType("admin", admin_fee_type)} />
        <InfoCard label="Late Admin Fee" value={getFeeByType("lateAdmin", late_admin_fee_mode)} />
      </div>
      {renderFormFields()}
      {formFields.length < MAX_INVOICE_FORM_LIMIT && (
        <button className="mt-10 flex justify-start items-center cursor-pointer" onClick={addFormFields}>
          <span className="w-6 h-6 bg-pandan-dark text-white rounded-full flex leading-[22px] justify-center font-bold">
            +
          </span>
          <p className="font-bold text-sm ml-3 text-pandan-dark">Add Invoice</p>
        </button>
      )}
      <div className="mt-10 flex justify-end">
        <Button
          modifier={{
            disabled: loading,
            className: "w-[144px]",
          }}
          events={{
            onClick: handleSubmit,
          }}
        >
          Continue
        </Button>
      </div>
    </LoanApplicationLayout>
  );
};

export default InvoiceForm;
