import { useState, useEffect } from "react";

const Tab = ({ modifier = {}, children = null }) => {
  const { defaultTab } = modifier;
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (defaultTab) {
      setActiveTab(defaultTab);
    }
  }, [defaultTab]);

  const tabs = children.map((child, index) => (
    <div
      key={index}
      onClick={() => setActiveTab(child.props.tabId)}
      className={`transition-all border-b-2 ${
        child.props.tabId === activeTab
          ? "text-black border-b-matcha "
          : "text-pepper-light border-b-transparent"
      } mr-8 last:mr-0 text-center p-2`}
    >
      <div className="flex items-center justify-center cursor-pointer">
        {child.props.icon && (
          <img
            className={`mr-3 ${
              child.props.tabId === activeTab ? "" : "grayscale-[100]"
            }`}
            src={child.props.icon}
            alt="Icon"
          />
        )}
        <h5 className="text-lg font-bold">{child.props.label}</h5>
      </div>
    </div>
  ));

  const tabContent = children.filter(
    (child) => child.props.tabId === activeTab
  );

  return (
    <div className="relative h-full pb-4">
      <div className="flex">{tabs}</div>
      <div className="tab-content">{tabContent}</div>
    </div>
  );
};

export default Tab;
