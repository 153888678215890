import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchAPI from "helpers/fetchAPI";
import { toast } from "react-toastify";

const name = "company";
const reducers = {
  setSelectedCompany: (state, action) => {
    state.companySelected = action.payload;
  },
};
const initialState = {
  loading: 0,
  companyId: "",
  companyType: "",
  companyDetail: {
    id: null,
    legal_corporate_name: "",
    company_identifier: null,
    corporate_npwp_number: "",
    phone_number: "",
    industry: "",
    number_of_employees: "",
    year_of_established: null,
    business_desc: "",
    number_of_bod_in_the_deed: null,
    limit_assignment: null,
    address: "",
    province_id: null,
    city_id: null,
    district_id: null,
    subdistrict_id: null,
    postal_code: null,
    bank_account_name: "",
    bank_account_number: "",
    bank_name: "",
    bank_code: "",
    need_revision: null,
    bank_account_type: "",
    step: 1,
    pre_application_id: "",
    company_reference_id: null,
    current_active_role: "",
    status: "",
    fdc_reference_id: "",
    clik_reference_id: "",
    created_by: "",
    modified_by: "",
    company_ptr: null,
    type: "",
    displayed_status: "",
    created_at: "",
    company_type: "",
    displayed_files: [],
    member: [],
    province_name: "",
    city_name: "",
    district_name: "",
    subdistrict_name: "",
  },
  limitInformation: {
    underlying: {
      bc_total_limit: 0,
      uc_total_limit: 0,
      uc_assigned_limit: 0,
      remaining_limit: 0,
      available_limit: 0,
      total_active: 0,
      on_boarded_date: "2022-01-25T08:15:25.673Z",
      product_name: "",
      total_due: 0,
      status: "",
      company_id: null,
      lead_gen_fee: 0,
      legal_corporate_name: "",
      company_reference_id: "",
    },
    borrower: {
      bc_total_limit: 0,
      uc_total_limit: 0,
      uc_assigned_limit: 0,
      remaining_limit: 0,
      available_limit: 0,
      total_active: 0,
      on_boarded_date: "2022-01-25T08:15:25.673Z",
      product_name: "",
      total_due: 0,
      status: "",
      company_id: null,
      lead_gen_fee: 0,
      legal_corporate_name: "",
      company_reference_id: "",
    },
  },
  borrower: [],
  companySelected: null,
};

export const getCompanyInformation = createAsyncThunk(
  `${name}/getCompanyInformation`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await FetchAPI.get("company/detail");
      return response?.data?.data || {};
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const getLimitInformation = createAsyncThunk(
  `${name}/getLimitInformation`,
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await FetchAPI.get(`company/limit-detail/${companyId}`);
      return response?.data?.data?.attributes || {};
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const getUCLimitInformation = createAsyncThunk(
  `${name}/getUCLimitInformation`,
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await FetchAPI.get(`company/limit-detail/${companyId}`);
      return response?.data?.data?.attributes || {};
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const getBorrowerCompany = createAsyncThunk(`${name}/getBorrowerCompany`, async (_, { rejectWithValue }) => {
  try {
    const response = await FetchAPI.get(`company/borrower`);
    return response?.data?.data || [];
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const createExtraReducers = () => {
  const companyInformation = () => {
    const { pending, fulfilled, rejected } = getCompanyInformation;

    return {
      [pending]: (state) => {
        state.loading += 1;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading -= 1;
        state.companyId = payload.id;
        state.companyType = payload.type;
        state.companyDetail = payload.attributes;
      },
      [rejected]: (state, { payload }) => {
        state.loading -= 1;
        toast.error(payload.response_message || payload.message);
      },
    };
  };

  const BCLimitInformation = () => {
    const { pending, fulfilled, rejected } = getLimitInformation;
    return {
      [pending]: (state) => {
        state.loading += 1;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading -= 1;
        state.limitInformation.borrower = payload;
      },
      [rejected]: (state, { payload }) => {
        state.loading -= 1;
        toast.error(payload.response_message || payload.message);
      },
    };
  };

  const UCLimitInformation = () => {
    const { pending, fulfilled, rejected } = getUCLimitInformation;
    return {
      [pending]: (state) => {
        state.loading += 1;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading -= 1;
        state.limitInformation.underlying = payload;
      },
      [rejected]: (state, { payload }) => {
        state.loading -= 1;
        toast.error(payload.response_message || payload.message);
      },
    };
  };

  const companyBorrower = () => {
    const { pending, fulfilled, rejected } = getBorrowerCompany;
    return {
      [pending]: (state) => {
        state.loading += 1;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading -= 1;
        state.borrower = payload;
      },
      [rejected]: (state, { payload }) => {
        state.loading -= 1;
        toast.error(payload);
      },
    };
  };

  return { ...companyInformation(), ...UCLimitInformation(), ...companyBorrower(), ...BCLimitInformation() };
};

const extraReducers = createExtraReducers();

export const companySlice = createSlice({ name, initialState, reducers, extraReducers });
export const {
  actions: { setSelectedCompany },
} = companySlice;
export const companyReducer = companySlice.reducer;
