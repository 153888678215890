const bagIcon = require("assets/icons/bag.svg").default;
const userIcon = require("assets/icons/user.svg").default;
const leftArrowIcon = require("assets/icons/arrow-left.svg").default;
const arrowDownIcon = require("assets/icons/scroll-arrow-down.svg").default;
const documentIcon = require("assets/icons/document.svg").default;
const walletIcon = require("assets/icons/wallet.svg").default;
const filesIcon = require("assets/icons/files.svg").default;
const clearIcon = require("assets/icons/clear.svg").default;
const exclamationIcon = require("assets/icons/exclamation.svg").default;
const fileIcon = require("assets/icons/file.svg").default;
const chevronRight = require("assets/icons/chevron-right-green.svg").default;

export {
  bagIcon,
  userIcon,
  leftArrowIcon,
  arrowDownIcon,
  documentIcon,
  walletIcon,
  filesIcon,
  clearIcon,
  exclamationIcon,
  fileIcon,
  chevronRight,
};
