export const MAX_INVOICE_FORM_LIMIT = 25;

export const ALL_FIELDS = ["proposed_loan_amount", "invoice_number", "invoice_amount", "issued_date", "tenure"];

export const DEFAULT_FORM_FIELDS = {
  id: "",
  invoice_number: "",
  invoice_amount: 0,
  proposed_loan_amount: 0,
  issued_date: "",
  tenure: 0,
};

export const ERROR_API_MESSAGES = {
  proposed_loan_amount: "Your proposal is greater than your available limit.",
  invoice_number: "Invoice number has been submitted before. Use another invoice.",
};

export const ERROR_VALIDATION_MESSAGES = {
  proposed_loan_amount: "The proposed loan amount should be less than the invoice value",
  issued_date: "The invoice issued date maximum date is today.",
};
