import { errorIllustration } from "config/images";

const Error = ({ title, subTitle, imgIllustation }) => {
  return (
    <div className="flex flex-col justify-center my-6">
      <img className="w-80 h-44 m-auto" src={imgIllustation || errorIllustration} alt="error" />
      <div className="flex flex-col text-center align-center justify-center">
        <span className="text-shallot-darkest font-bold align-middle text-[32px]">{title}</span>
        <span className="text-pepper-dark text-pro text-sm">{subTitle}</span>
      </div>
    </div>
  )
}

export default Error;
