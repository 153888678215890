import { useState } from "react";
import { useSelector } from "react-redux";

const gravatarBg = require("assets/images/gravatar.svg").default;

const UserDropdown = ({ attributes, children }) => {
  const {
    user: { name, role, initial, imgSrc = gravatarBg },
  } = attributes;
  const [isOpen, setIsOpen] = useState(false);
  const { loading } = useSelector((state) => state.company);

  const renderRoleName = () => {
    if (loading) return <p className="h-2 mt-2 bg-pepper-light rounded w-28 animate-pulse" />;

    return <p className="text-xs font-normal text-pepper-dark">{role}</p>;
  };

  return (
    <div className="flex relative">
      <button
        className="flex items-center group text-left hover:bg-pepper-lighter hover:transition-all duration-300 p-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="relative text-center">
          <img className="w-8 h-8 rounded-full" src={imgSrc} alt="User avatar" />
          <span className="absolute text-sm font-bold top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {initial}
          </span>
        </div>
        <div className="flex flex-col ml-4 max-w-[176px] w-44">
          <p className="text-sm font-bold">{name}</p>
          {renderRoleName()}
        </div>
        <svg className="ml-4" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 5.5311L1.70711 1.23821C1.31658 0.847681 0.683417 0.847681 0.292893 1.23821C-0.0976305 1.62873 -0.0976305 2.2619 0.292893 2.65242L5.29289 7.65242C5.68342 8.04294 6.31658 8.04294 6.70711 7.65242L11.7071 2.65242C12.0976 2.2619 12.0976 1.62873 11.7071 1.23821C11.3166 0.847681 10.6834 0.847681 10.2929 1.23821L6 5.5311Z"
            fill="#594D75"
          />
        </svg>
      </button>
      <ul
        className={`absolute top-[72px] rounded-md shadow-md w-full bg-white transition-all duration-500
          ${isOpen ? "block" : "hidden"}`}
      >
        {children}
      </ul>
    </div>
  );
};

const Item = ({ children }) => {
  return <li className="p-3 text-sm font-medium hover:cursor-pointer hover:bg-pepper-lighter">{children}</li>;
};

UserDropdown.Item = Item;

UserDropdown.defaultProps = {
  attributes: {
    user: {
      name: "User",
      role: "Role",
      initial: "",
      imgSrc: gravatarBg,
    },
  },
};

export default UserDropdown;
