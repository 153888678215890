import { useState } from "react";

import { currency, pluralCheck } from "helpers/formatter"
import Button from "components/Button";
import Card from "components/Card";
import Chip from "components/Chips";
import Modal from "components/Modal";
import { CONSTANT } from "config/common";

import Invoice from "../Invoice"
import { APP_STATUS } from "config/status";

const ApplicationItem = ({ application, key }) => {
  const [show, setShow] = useState(false)

  const renderCardContent = application => (
    <Card>
      <div className="pb-6 flex justify-between">
        <div>
          <span className="text-sm font-normal text-pepper-dark mr-2">Application ID</span>
          <span className="text-sm font-bold">{application.id}</span>
        </div>
        <div>
          <Chip label={APP_STATUS.PROGRESS} type="info" />
        </div>
      </div>
      <div className="absolute border-b-[1px] border-pepper-lighter left-0 right-0" />
      <div className="pt-6">
        <div className="grid grid-cols-6 gap-4">
          <div className="flex flex-col border-r-[1px] py-2 pr-6 space-y-2">
            <Button modifier={{ secondary: true, className: "h-8 py-0 text-xs" }} events={{ onClick: () => setShow(true) }}>
              {pluralCheck(application?.invoices.length, "Invoice")}
            </Button>
          </div>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Approved Loan Amount</p>
            <p className="text-xs font-bold text-pepper-dark">{currency(application?.approved_loan_amount) || CONSTANT.NA}</p>
          </div>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Total Invoice Amount</p>
            <p className="text-xs font-bold text-pepper-dark">{currency(application?.total_invoice_amount) || CONSTANT.NA}</p>
          </div>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Tenure</p>
            <p className="text-xs font-bold text-pepper-dark">{application.tenure ? pluralCheck(application.tenure, "Day") : CONSTANT.NA}</p>
          </div>
          <div className="flex flex-col py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Interest Rate (30 Days)</p>
            <p className="flex items-center text-xs font-bold text-pepper-dark">
              {application?.interest_rate_per_30_days ? `${application?.interest_rate_per_30_days}%` : CONSTANT.NA}
            </p>
          </div>
        </div>
      </div>
    </Card>
  )

  return (
    <>
      <Modal modifier={{show, classNameContent: "bg-transparent w-[1200px] p-0 top-[25%]" }} events={{ closeModal: () => setShow(false) }}>
        {renderCardContent(application)}
        <div className="mt-8 p-4 bg-white rounded-lg">
          <Invoice invoices={application.invoices} />
        </div>
      </Modal>
      <div className="mt-8 relative" key={key}>
        {renderCardContent(application)}
      </div>
    </>
  )
}

export default ApplicationItem
