const ProgressBar = ({ currentStep = 1, labels = [] }) => {
  const stepSize = labels.length;
  const barSize = stepSize - 1;
  const barWidth = `calc((100% - (${stepSize} * 40px)) / ${barSize})`;
  const currentStepIndex = currentStep - 1;

  const Label = ({ text, className }) => (
    <label className={`${className} absolute text-center w-[88px] font-bold text-xs top-12`}>{text}</label>
  );

  const Step = ({ num, text, passed }) => (
    <div className="relative flex items-center justify-center">
      <div
        className={`w-10 h-10 flex items-center justify-center rounded-full border-2 border-white ${
          passed ? "bg-pandan-dark" : "bg-pepper-light"
        }`}
      >
        <span className="text-white text-center font-bold">{num}</span>
      </div>
      <Label text={text} className={`${passed ? "text-black" : "text-pepper-light"}`} />
    </div>
  );

  const Bar = ({ passed }) => (
    <div
      style={{
        width: barWidth,
      }}
      className={`${passed ? "bg-pandan-dark" : "bg-pepper-light"} h-[3px] last:hidden`}
    />
  );

  const renderSteps = () =>
    labels.map((label, index) => {
      return (
        <>
          <Step key={`step-${index}`} num={index + 1} text={label} passed={index <= currentStepIndex} />
          <Bar key={`bar-${index}`} passed={index < currentStepIndex} />
        </>
      );
    });

  return <div className="flex items-center justify-between pb-4 w-full">{renderSteps()}</div>;
};

export default ProgressBar;
