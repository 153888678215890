/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { getBorrowerCompany, getLimitInformation, getUCLimitInformation } from "features/company.slice";
import { currency } from "helpers/formatter";
import { COMPANY_TYPE } from "config/company";

import Button from "components/Button";
import Card from "components/Card";
import Chip from "components/Chips";
import AlertModal from "components/Modal/AlertModal";

const type = {
  underlying_company: "underlying",
  borrower_company: "borrower",
};

const Portfolio = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, toggleModal] = useState(false);

  const { companyId, companyType, limitInformation: limit, borrower, loading } = useSelector((state) => state.company);
  const limitInformation = limit[type[companyType]] || {};
  const { product_name: productName } = limitInformation;

  const isBorrowerCompany = companyType === COMPANY_TYPE.BORROWER_COMPANY;
  const isUnderlyingCompany = companyType === COMPANY_TYPE.UNDERLYING_COMPANY;

  useEffect(() => {
    if (companyId && !productName) {
      if (isUnderlyingCompany) {
        dispatch(getUCLimitInformation(companyId));
        dispatch(getBorrowerCompany());
      } else if (isBorrowerCompany) {
        dispatch(getLimitInformation(companyId));
      }
    }
  }, [companyId, productName, companyType]);

  const renderFieldByCompanyType = () => {
    if (isBorrowerCompany) {
      return (
        <div className="flex flex-col border-r-[1px] py-1 space-y-2">
          <p className="text-xs text-pepper-dark">Borrower Total Limit</p>
          <p className="text-xs font-bold text-pepper-dark">{currency(limitInformation.bc_total_limit) || "N/A"}</p>
        </div>
      );
    } else {
      return (
        <>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">UC Total Limit</p>
            <p className="text-xs font-bold text-pepper-dark">{currency(limitInformation.uc_total_limit) || "N/A"}</p>
          </div>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Total Assigned Limit</p>
            <p className="text-xs font-bold text-pepper-dark">
              {currency(limitInformation.uc_assigned_limit) || "N/A"}
            </p>
          </div>
        </>
      );
    }
  };

  const handleDetailButton = () => {
    if (isUnderlyingCompany && borrower.length === 0) {
      toggleModal(true);
    } else {
      navigate("/portfolio/detail");
    }
  };

  return (
    <div className="max-w-6xl m-auto mt-12">
      <Card attributes={{ bgOn: false }} modifier={{ loading: loading }}>
        <div className={`grid grid-cols-${isBorrowerCompany ? "7" : "8"} gap-4`}>
          <div className="flex flex-col border-r-[1px] col-span-2">
            <div className="flex items-center">
              <p className="text-lg font-bold text-shallot-darkest mr-2">OVO Modal Usaha</p>
              <Chip type="success" label="Active" />
            </div>
            <p className="text-sm font-normal text-pepper-dark">
              Onboarded: {moment(limitInformation.on_boarded_date).format("MMMM Do, YYYY")}
            </p>
          </div>

          {renderFieldByCompanyType()}
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Available Limit</p>
            <p className="text-xs font-bold text-pepper-dark">{currency(limitInformation.available_limit) || "N/A"}</p>
          </div>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Total Active Loans</p>
            <p className="text-xs font-bold text-pepper-dark">{limitInformation.total_active}</p>
          </div>
          <div className="flex flex-col py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Total Remaining O/S</p>
            <p className="text-xs font-bold text-pepper-dark">{currency(limitInformation.total_due) || "N/A"}</p>
          </div>
          <div className="flex flex-col py-2 space-y-2">
            <Button
              modifier={{ secondary: true, className: "h-8 py-0 text-xs" }}
              events={{ onClick: handleDetailButton }}
            >
              Details
            </Button>
          </div>
        </div>

        <AlertModal
          modifier={{
            show: showModal,
            heading: "You don't have any borrower yet",
            subheading: "You can see the details after a borrower is assigned to you.",
            imageSrc: "https://lending-assets.oss-ap-southeast-5.aliyuncs.com/webview/illustration/error.svg",
            imageAlt: "People clapping hand together",
          }}
          events={{
            closeModal: () => toggleModal(false),
            handleConfirm: () => toggleModal(false),
          }}
        />
      </Card>
    </div>
  );
};

export default Portfolio;
