import { useNavigate } from "react-router-dom";

import LoanApplicationLayout from "layouts/LoanApplicationLayout";
import Uploader from "components/Uploader";
import DocumentInformation from "./DocumentInformation";
import Button from "components/Button";

const picLists = [
  { title: "Borrower Identifier (KTP/NPWP)*", type: "upload" },
  { title: "NIB/SIUP + TDP*", type: "upload" },
  { title: "Deed of Establishment*", type: "upload" },
  { title: "Latest Company Deed + SKMenKumHAM*", type: "detail" },
  { title: "Domicile Letter", type: "upload" },
  { title: "Company Profile", type: "upload" },
  { title: "Current Asset Details", type: "detail" },
  { title: "Current Liability Details", type: "detail" },
  { title: "Shareholder Information", type: "detail" },
  { title: "Others*", type: "detail" },
  { title: "Other Director's KTP", type: "upload" },
];

const financialLists = [
  { title: "Financial Statements*", type: "detail" },
  { title: "Bank Statement*", type: "detail" },
  { title: "Aging Report (AR/AP)*", type: "upload" },
];

const generateButton = (list) => {
  if (list.type === "upload") {
    return <Uploader title={list.title} />;
  }
  return <DocumentInformation title={list.title} />;
};

const DocumentUpload = () => {
  const navigate = useNavigate();
  return (
    <div>
      <LoanApplicationLayout progressBarStep={2} cardWidth="w-[1168px]">
        <h1 className="text-3xl font-bold text-pepper-darker">Document Upload</h1>
        <label className="text-sm font-medium text-pepper-darker mt-3 mb-10">
          Upload all of the document needed below to continue.
        </label>
        <h4 className="text-base font-bold mt-16">Supporting Documents</h4>
        <div className="grid grid-cols-2 gap-8 my-8">{picLists && picLists.map((list) => generateButton(list))}</div>
        <hr />
        <div className="grid grid-cols-2 gap-8 my-8">
          {financialLists && financialLists.map((list) => generateButton(list))}
        </div>
        <div className="mt-10 flex justify-end ">
          <Button
            modifier={{
              disabled: false,
              className: "w-[144px] text-[#666666] bg-transparent hover:bg-transparent hover:underline",
            }}
            events={{
              onClick: () => navigate("/loan-application/invoice-form"),
            }}
          >
            Back
          </Button>
          <Button
            modifier={{
              disabled: false,
              className: "w-[144px]",
            }}
            events={{
              onClick: () => navigate("/loan-application/terms-conditions"),
            }}
          >
            Continue
          </Button>
        </div>
      </LoanApplicationLayout>
    </div>
  );
};

export default DocumentUpload;
