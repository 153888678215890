import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RESOURCE_API_URL } from "api";
import { CONSTANT } from "config/common";
import FetchAPI from "helpers/fetchAPI";

const name = "loan"

const getLoans = () => {
  return createAsyncThunk(`${name}/getLoans`, async (payloads, { rejectWithValue }) => {
    try {
      const response = await FetchAPI.post(RESOURCE_API_URL.LOAN_LIST, payloads)
      return response?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  })
}

const getLoanDetail = () => {
  return createAsyncThunk(`${name}/getLoanDetail`, async (loan_id, { rejectWithValue }) => {
    try {
      const response = await FetchAPI.get(`loan/${loan_id}/loan-detail`)
      return response?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  })
}

const getLoanRepayment = () => {
  return createAsyncThunk(`${name}/getLoanRepayment`, async (loan_id, { rejectWithValue }) => {
    try {
      const response = await FetchAPI.get(`loan/${loan_id}/loan-repayment-history`)
      return response?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  })
}

const createInitialState = () => {
  return {
    loading: 0,
    loans: {
      data: [
        {
          application_id: "",
          approved_loan_invoice_amount: "",
          contract_id: "",
          invoice_amount: "",
          tenure: "",
          invoices: []
        }
      ],
      total_records: 0,
      error: null
    },
    loan_detail: {
      data: {},
      error: null
    },
    loan_repayment: {
      data: [],
      error: null
    },
    loan_id_selected: null,
  }
}

const createReducers = () => {
  return {
    setLoanId: (state, action) => { state.loan_id_selected = action.payload },
    unloadDetail: (state, action) => {
      state.loan_detail = {}
      state.loan_repayment = {}
      state.loan_id_selected = null
    }
  }
}

const createExtraActions = () => {
  return {
    getLoans: getLoans(),
    getLoanDetail: getLoanDetail(),
    getLoanRepayment: getLoanRepayment()
  }
}

const createExtraReducers = () => {
  const loans = () => {
    const { pending, fulfilled, rejected } = extraActions.getLoans;
    return {
      [pending]: state => {
        state.loading += 1
      },
      [fulfilled]: (state, action) => {
        state.loading -= 1;
        state.loans = action.payload;
        state.loans.error = null
      },
      [rejected]: (state, action) => {
        state.loading -= 1;
        state.loans = [];
        state.loans.error = action.payload.response_message || action.payload.message || CONSTANT.ERROR_MSG_GENERAL
      }
    }
  }
  const loanDetail = () => {
    const { pending, fulfilled, rejected } = extraActions.getLoanDetail;
    return {
      [pending]: state => {
        state.loading += 1
      },
      [fulfilled]: (state, action) => {
        state.loading -= 1;
        state.loan_detail.data = action.payload.data;
        state.loan_detail.error = null
      },
      [rejected]: (state, action) => {
        state.loading -= 1;
        state.loan_detail.data = null;
        state.loan_detail.error = action.payload.response_message || action.payload.message || CONSTANT.ERROR_MSG_GENERAL
      }
    }
  }
  const loanRepayment = () => {
    const { pending, fulfilled, rejected } = extraActions.getLoanRepayment;
    return {
      [pending]: state => {
        state.loading += 1
      },
      [fulfilled]: (state, action) => {
        state.loading -= 1;
        state.loan_repayment.data = action.payload.data;
        state.loan_repayment.error = null
      },
      [rejected]: (state, action) => {
        state.loading -= 1;
        state.loan_repayment.data = null;
        state.loan_repayment.error = action.payload.response_message || action.payload.message || CONSTANT.ERROR_MSG_GENERAL
      }
    }
  }
  return { ...loans(), ...loanDetail(), ...loanRepayment() }
}

const initialState = createInitialState()
const reducers = createReducers()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()

export const loanSlice = createSlice({
  name,
  initialState,
  reducers,
  extraReducers,
})

export const loanActions = { ...loanSlice.actions, ...extraActions };
export const loanReducer = loanSlice.reducer;