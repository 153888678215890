import { useState } from "react";

import Button from "components/Button";
import Modal from "components/Modal";
import Uploader from "components/Uploader";

import { exclamationIcon, chevronRight } from "config/icons";

const DocumentInformation = ({ title }) => {
  const [showModal, toggleModal] = useState(false);
  const [uploadFields, setUploadFields] = useState(0);
  const [timestamp, setTimestamp] = useState("-");

  const renderUploadFields = () => {
    const fields = [];

    for (let i = 0; i < uploadFields; i++) {
      fields.push(
        <div className="mt-6">
          <Uploader title="Others" setTimestamp={setTimestamp} />
        </div>
      );
    }

    return fields;
  };

  return (
    <>
      <div className="flex justify-between items-center bg-[#FBFBFB] p-4 rounded-md">
        <div className="flex flex-col">
          <label className="flex text-sm font-medium text-black">
            {title}
            <img className="ml-1" src={exclamationIcon} alt="exclamation-icon" />
          </label>
          <label className="text-sm font-medium text-pepper-light">{timestamp}</label>
        </div>
        <Button
          modifier={{
            secondary: true,
            className: "flex items-center py-3 px-6 min-w-fit w-auto h-8 text-xs text-center",
          }}
          events={{
            onClick: () => toggleModal(true),
          }}
        >
          Details
          <img className="ml-3" src={chevronRight} alt="arrow-right" />
        </Button>
      </div>
      <div className="absolute left-0 right-0">
        <Modal modifier={{ show: showModal }} events={{ closeModal: () => toggleModal(false) }}>
          <div className="flex justify-between items-center">
            <h2 className="text-base font-bold">Others</h2>
            <span className="text-3xl cursor-pointer" onClick={() => toggleModal(false)}>
              &#215;
            </span>
          </div>
          <button
            className="mt-10 flex justify-start items-center cursor-pointer"
            onClick={() => setUploadFields(uploadFields + 1)}
          >
            <span className="w-6 h-6 bg-pandan-dark text-white rounded-full flex leading-[22px] justify-center font-bold">
              +
            </span>
            <p className="font-bold text-sm ml-3 text-pandan-dark">Add Document</p>
          </button>
          {renderUploadFields()}
        </Modal>
      </div>
    </>
  );
};

export default DocumentInformation;
