import { Children, cloneElement, isValidElement, useEffect, useRef, useState } from "react"

const arrowDown = (
  <img alt="Arrow Down" src={require("assets/icons/arrow-down.svg").default} />
);

const useOutsideComponent = (ref, setIsOpen) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      };
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [ref])
}

const Select = ({ onChange, defaultValue, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [defaultOption, setDefaultOption] = useState(null);
  const selectOptionRef = useRef(null);
  useOutsideComponent(selectOptionRef, setIsOpen)

  const toggle = () => setIsOpen(!isOpen);

  const optionOnClick = (value, child) => {
    setIsOpen(false);
    setOptionSelected(child);
    onChange({ value, title: child });
  };

  useEffect(() => {
    Children.toArray(children).map((child, idx) => {
      if (!defaultValue || defaultValue === "" || child.props.value !== defaultValue) {
        // set to first option if no default value or no match
        if (idx === 0) {
          setDefaultOption(child.props.children);
        };
      };
      if (child.props.value === defaultValue) {
        setDefaultOption(child.props.children);
      };
    });
  }, [defaultOption]);

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { onClick: optionOnClick });
    };
  });

  return (
    <div className="relative" ref={selectOptionRef}>
      <button
        className="bg-pepper-lighter relative w-full rounded-md shadow-sm py-1 pl-3 pr-10 text-left
          transition hover:ring-2 ring-pepper-light focus:ring-2"
        onClick={toggle}
      >
        <span className="text-base font-bold text-shallot-darkest">
          {optionSelected || defaultOption}
        </span>
        <span className="absolute right-0 pr-[15px] items-center inset-y-0 flex">
        {arrowDown}
        </span>
      </button>
      <ul
        className={`bg-pepper-white absolute rounded-md w-full max-h-56 mt-2 text-base py-1 shadow-md z-10 overflow-auto
          ring-2 ring-pepper-lighter ring-opacity-50 transition-all ${isOpen ? "block" : "hidden"}`}>
        {childrenWithProps}
      </ul>
    </div>
  );
};

const Option = ({ disabled = false, onClick, value, children }) => {
  const optionOnClick = (value, child) => () => {
    if (!disabled) {
      onClick(value, child);
    };
  };

  return (
    <>
      <li
        className={`
          py-2 pl-3 relative select-none text-sm font-medium cursor-pointer
          ${disabled ?
            "bg-[#FBFBFB] cursor-not-allowed text-pepper-light" :
            "bg-white text-shallot-darkest hover:bg-pepper-lighter hover:text-black hover:font-bold transition"}
        `}
        disabled={disabled}
        onClick={optionOnClick(value, children)}>
          {children}
      </li>
    </>
  );
};

Select.Option = Option;

export default Select;
