import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { loanActions } from "features/loan.slice";
import { setLoading } from "features/loading.slice";
import { currency, currencyOrPercentage } from "helpers/formatter";
import { REPAYMENT_COLUMN, REPAYMENT_TYPE } from "config/loan";

import List from "components/List";
import Modal from "components/Modal";
import Tab from "components/Tab";
import Table from "components/Table";
import Error from "components/Card/Error";
import { documentIcon, filesIcon, walletIcon } from "config/icons";
import { COMPANY_TYPE } from "config/company";


const LoanDetail = ({ loan_id, showDetail, setShowDetail }) => {
  const { getLoanDetail, getLoanRepayment, unloadDetail } = loanActions;
  const dispatch = useDispatch();
  const {
    loan_detail: { data: loan_detail_data, error: loan_detail_error },
    loan_repayment: { data: loan_repayment_data, error: loan_repayment_error },
    loan_id_selected,
    loading
  } = useSelector((state) => state.loan)
  const { companyType } = useSelector((state) => state.company)

  const repayment = loan_repayment_data && loan_repayment_data.map(repayment => ({
    period: moment(repayment.period).format("DD/MM/YYYY"),
    repayment_amount: currency(repayment.repayment_amount),
    repayment_type: REPAYMENT_TYPE[repayment?.repayment_type],
    transaction_id: repayment.id
  }))

  useEffect(() => {
    dispatch(getLoanDetail(loan_id_selected))
    dispatch(getLoanRepayment(loan_id_selected))
  }, [loan_id_selected])

  dispatch(setLoading(loading));

  const handleCloseDetail = () => {
    setShowDetail(false)
    dispatch(unloadDetail())
  }
  return (
    <Modal modifier={{ show: showDetail, classNameContent: "w-[1120px] top-[20%]" }} events={{ closeModal: handleCloseDetail}}>
      <Tab>
        <div className="py-6" label="Loan Detail" tabId={1} icon={documentIcon}>
          {loan_detail_data && 
            <List column={2}>
              <List.Item label="Principal">{currency(loan_detail_data.principle_amount)}</List.Item>
              <List.Item label="Interest">{currency(loan_detail_data.interest_fee_amount)}</List.Item>
              <List.Item label="Late Interest Fee">{currency(loan_detail_data.late_interest_fee_amount)}</List.Item>
              <List.Item label="Admin Fee">{currency(loan_detail_data.admin_fee_amount)}</List.Item>
              <List.Item label="Late Admin Fee">{currency(loan_detail_data.late_admin_fee_amount)}</List.Item>
              <List.Item label="Settled Principal">{currency(loan_detail_data.principle_settle_amount)}</List.Item>
              <List.Item label="Settled Interest">{currency(loan_detail_data.interest_fee_settled)}</List.Item>
              <List.Item label="Settled Late Interest Fee">{currency(loan_detail_data.late_interest_fee_settled)}</List.Item>
              <List.Item label="Settled Admin Fee">{currency(loan_detail_data.admin_fee_settled)}</List.Item>
              <List.Item label="Settled Late Admin Fee">{currency(loan_detail_data.late_admin_fee_settled)}</List.Item>
            </List>}
          {loan_detail_error && <Error title={"Something went wrong"} subTitle={`Please contact the administrator`} />}
        </div>
        <div className="py-6" label="Pricing" tabId={2} icon={walletIcon}>
          {loan_detail_data && 
            <List column={1}>
              {
                companyType === COMPANY_TYPE.UNDERLYING_COMPANY && (
                  <List.Item label="Lead Gen Fee">
                    {currencyOrPercentage(loan_detail_data.lead_gen_fee, "percentage")}
                  </List.Item>
                )
              }
              <List.Item label="Interest Rate per 30 days">
                {currencyOrPercentage(loan_detail_data.interest_fee_settled_value, loan_detail_data.interest_fee_settled_type)}
              </List.Item>
              <List.Item label="Late Interest Rate per 1 Late Day">
                {currencyOrPercentage(loan_detail_data.late_interest_fee_settled_value, loan_detail_data.late_interest_fee_settled_type)}
              </List.Item>
              <List.Item label="Admin Fee">
                {currencyOrPercentage(loan_detail_data.admin_fee_settled_value, loan_detail_data.admin_fee_settled_type)}
              </List.Item>
              <List.Item label="Late Admin Fee">
                {currencyOrPercentage(loan_detail_data.late_admin_fee_settled_value, loan_detail_data.late_admin_fee_settled_type)}
              </List.Item>
            </List>}
          {loan_detail_error && <Error title={loan_detail_error || "Something went wrong"} subTitle={`Please contact the administrator`} />}
        </div>
        <div className="py-6" label="Repayment History" tabId={3} icon={filesIcon}>
          {repayment && <Table columns={REPAYMENT_COLUMN} data={repayment} />}
          {loan_repayment_error && <Error title={loan_repayment_error ||"Something went wrong"} subTitle={`Please contact the administrator`} />}
        </div>
      </Tab>
    </Modal>
  )
}

export default LoanDetail;
