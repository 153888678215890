import Health from "views/Health";
import NotFound from "views/NotFound";
import Portfolio from "views/Main/Portfolio";
import Profile from "views/Main/Profile";
import Detail from "views/Main/Portfolio/PortfolioDetail";
import InvoiceForm from "views/Main/LoanApplication/InvoiceForm";
import TermsConditions from "views/Main/LoanApplication/TermsConditions";
import DocumentUpload from "views/Main/LoanApplication/DocumentUpload";
import Authentication from "views/Authentication";
import Login from "views/Login";

import { TOGGLE_KEY } from "config/common";

export const privateRoutes = [
  {
    element: <Detail />,
    exact: true,
    path: "/portfolio/detail",
  },
  {
    element: <Profile />,
    exact: true,
    path: "/profile",
  },
  {
    element: <InvoiceForm />,
    exact: true,
    path: "/loan-application/invoice-form",
    isToggleRoute: true,
    toggleKey: TOGGLE_KEY.invoiceSubmission,
  },
  {
    element: <TermsConditions />,
    exact: true,
    path: "/loan-application/terms-conditions",
    isToggleRoute: true,
    toggleKey: TOGGLE_KEY.invoiceSubmission,
  },
  {
    element: <DocumentUpload />,
    exact: true,
    path: "/loan-application/document",
    isToggleRoute: true,
    toggleKey: TOGGLE_KEY.documentUpload,
  },
];

export const homeMainRoutes = [
  {
    element: <Portfolio />,
    exact: true,
    path: "/",
  },
];

export const routes = [
  {
    element: <Health />,
    exact: true,
    path: "/health",
  },
  {
    element: <Authentication />,
    exact: true,
    path: "/authenticated",
  },
  {
    element: <Login />,
    exact: true,
    path: "/login",
  },
  {
    element: <NotFound />,
    path: "*",
  },
];
