import "./Card.css";

const Card = ({ attributes, modifier, children }) => {
  const { bgOn } = attributes;
  const { loading } = modifier;

  return (
    <div className={`${bgOn ? "card-bg " : ""}min-h-[104px] bg-white rounded-[10px] p-6`}>
      {loading ? <LoadingCard /> : children}
    </div>
  )
}

const LoadingCard = () => {
  return (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-4 py-1">
        <div className="h-2 bg-pepper-light rounded" />
        <div className="grid grid-cols-8 gap-8">
          <div className="h-2 bg-pepper-light rounded col-span-2" />
          <div className="h-2 bg-pepper-light rounded" />
          <div className="h-2 bg-pepper-light rounded" />
          <div className="h-2 bg-pepper-light rounded" />
          <div className="h-2 bg-pepper-light rounded" />
          <div className="h-2 bg-pepper-light rounded col-span-2" />
        </div>
        <div className="grid grid-cols-8 gap-8">
          <div className="h-2 bg-pepper-light rounded col-span-2" />
          <div className="h-2 bg-pepper-light rounded" />
          <div className="h-2 bg-pepper-light rounded" />
          <div className="h-2 bg-pepper-light rounded" />
          <div className="h-2 bg-pepper-light rounded" />
          <div className="h-2 bg-pepper-light rounded col-span-2" />
        </div>
      </div>
    </div>
  );
};

Card.defaultProps = {
  attributes: {
    bgOn: false
  },
  modifier: {
    loading: false
  }
}

export default Card;
