import { AUTH_API_URL } from "api";

const CLIENT_ID = process.env.REACT_APP_GRIFFIN_CLIENT_ID;
const USER_DASHBOARD_URL = process.env.REACT_APP_USER_DASHBOARD_URL

export const getAuthenticateURL = () => {
  const params = {
    response_type: 'code',
    state: `request-${Date.now()}`,
    client_id: CLIENT_ID
  }
  const encodedParams = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return `${AUTH_API_URL.AUTHORIZE}?${encodedParams}`;
}

export const getLogoutURL = () => {
  const params = { next_page: USER_DASHBOARD_URL }
  const encodedParams = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return `${AUTH_API_URL.LOGOUT}?${encodedParams}`;
}
