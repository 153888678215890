const Header = ({ children }) => {
  return (
    <header className="fixed w-full bg-white top-0 left-0 z-10">
      <div className="container flex items-center space-y-0 space-x-10 justify-between mx-auto px-4 py-4 max-h-[72px]">
        {children}
      </div>
    </header>
  )
}

export default Header;
