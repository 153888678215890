import { combineReducers } from "redux";

import { companyReducer } from "features/company.slice";
import { loadingReducer } from "features/loading.slice";
import { authReducer } from "features/auth.slice";
import { addInvoiceReducer } from "features/add-invoice.slice";
import { invoiceReducer } from "features/invoice.slice";
import { applicationReducer } from "features/application.slice";
import { loanReducer } from "features/loan.slice";

const rootReducer = combineReducers({
  auth: authReducer,
  company: companyReducer,
  loading: loadingReducer,
  addInvoice: addInvoiceReducer,
  invoice: invoiceReducer,
  application: applicationReducer,
  loan: loanReducer
});

export default rootReducer;
