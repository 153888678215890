import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./views/App";
import reportWebVitals from "./reportWebVitals";

import { datadogRum } from "@datadog/browser-rum";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

datadogRum.init({
  applicationId: process.env.REACT_APP_DD_APP_ID,
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
  service: "user-dashboard",
  sampleRate: 100,
  trackInteractions: true,
  env: process.env.REACT_APP_ENVIRONMENT,
  version: process.env.REACT_APP_VERSION,
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
    user: {
      key: process.env.REACT_APP_LD_USER_KEY,
    },
    flags: {
      "user-dashboard_document-upload_20220825": true,
      "user-dashboard_invoice-submission_20220912": true,
    },
  });

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
