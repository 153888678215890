const inprocessIll = require("assets/images/in_process.svg").default;

const Welcome = ({ subTitle, imgSrc }) => {
  const getWelcomingTitle = () => {
    const today = new Date();
    const currentHour = today.getHours();

    if (currentHour < 12) {
      return "Good morning!"
    } else if (currentHour < 18) {
      return "Good afternoon!"
    } else {
      return "Good evening!"
    }
  }

  return (
    <div className="flex flex-col items-center justify-center fixed left-0 bottom-0 right-0 top-0 max-w-sm max-h-screen m-auto">
      <img src={imgSrc} alt="People listening to music" />
      <p className="text-pepper-darker text-[32px] font-bold align-middle">{getWelcomingTitle()}</p>
      <p className="text-pepper-darker text-sm font-medium">{subTitle}</p>
    </div>
  )
}

Welcome.defaultProps = {
  subTitle: "Hope you have a great day.",
  imgSrc: inprocessIll
}

export default Welcome;
