/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Button";
import Card from "components/Card";
import Chip from "components/Chips";
import Modal from "components/Modal";
import Invoice from "../Invoice";
import Error from "components/Card/Error";

import { currency, pluralCheck } from "helpers/formatter";
import { LOAN_STATUS } from "config/status";
import { loanActions } from "features/loan.slice";
import { setLoading } from "features/loading.slice";

const LoanItem = ({ loan, key, clickDetail }) => {
  const dispatch = useDispatch();
  const { setLoanId, getLoanDetail, unloadDetail } = loanActions;
  const [show, setShow] = useState(false);

  const {
    loan_detail: { data: loan_detail_data, error: loan_detail_error },
    loading,
  } = useSelector((state) => state.loan);

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading]);

  const handleClickDetail = (loanId) => {
    dispatch(setLoanId(loanId));
    clickDetail(true);
  };

  const handleClickInvoice = () => {
    dispatch(getLoanDetail(loan.id));
    setShow(true);
  };

  const handleCloseModal = () => {
    dispatch(dispatch(unloadDetail()));
    setShow(false);
  };

  const renderFieldByStatus = (status) => {
    if (status === LOAN_STATUS.DISBURSED) {
      return (
        <>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Due Date</p>
            <p className="text-xs font-bold text-pepper-dark">{moment(loan.due_date).format("DD MMM YYYY")}</p>
          </div>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Remaining O/S</p>
            <p className="text-xs font-bold text-pepper-dark">{currency(loan.remaining_limit)}</p>
          </div>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Days Past Due</p>
            <p className="text-xs font-bold text-pepper-dark">{pluralCheck(loan.dpd, "Day")}</p>
          </div>
        </>
      );
    } else if (status === LOAN_STATUS.CLOSED) {
      return (
        <>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Paid At</p>
            <p className="text-xs font-bold text-pepper-dark">{moment(loan.paid_at).format("DD MMM YYYY")}</p>
          </div>
          <div className="flex flex-col border-r-[1px] py-1 space-y-2">
            <p className="text-xs text-pepper-dark">Paid Amount</p>
            <p className="text-xs font-bold text-pepper-dark">{currency(loan.amount_paid)}</p>
          </div>
        </>
      );
    }

    return null;
  };

  const renderCardContent = (loan, showDetail) => {
    return (
      <Card key={key}>
        <div className="pb-6 flex">
          <div className="mr-8">
            <span className="text-sm font-normal text-pepper-dark mr-2">Contract ID</span>
            <span className="text-sm font-bold">{loan.contract_id}</span>
          </div>
          <div className="mr-8">
            <span className="text-sm font-normal text-pepper-dark mr-2">Application ID</span>
            <span className="text-sm font-bold">{loan.application_id}</span>
          </div>
          <div className="mr-8">
            <span className="text-sm font-normal text-pepper-dark mr-2">Approved Loan Amount</span>
            <span className="text-sm font-bold">{currency(loan.approved_loan_invoice_amount)}</span>
          </div>
          <div className="mr-8">
            <span className="text-sm font-normal text-pepper-dark mr-2">Total Invoice Amount</span>
            <span className="text-sm font-bold">{currency(loan.invoice_amount)}</span>
          </div>
          <div className="mr-8">
            {loan.state === LOAN_STATUS.DISBURSED && moment(new Date()).isAfter(moment(loan.due_date)) && (
              <Chip label={`Overdue`} type={`warning`} />
            )}
          </div>
        </div>
        <div className="absolute border-b-[1px] border-pepper-lighter left-0 right-0" />
        <div className="pt-6">
          <div className={`grid ${loan.state === LOAN_STATUS.DISBURSED ? "grid-cols-7" : "grid-cols-6"} gap-4`}>
            <div className="flex flex-col border-r-[1px] py-2 pr-6 space-y-2">
              <Button
                modifier={{ secondary: showDetail, className: "h-8 py-0 text-xs" }}
                events={{ onClick: showDetail ? handleClickInvoice : () => {} }}
              >
                {showDetail ? "Invoices" : pluralCheck(loan_detail_data?.invoices?.length || [], "Invoice")}
              </Button>
            </div>
            {renderFieldByStatus(loan.state)}

            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Tenure</p>
              <p className="text-xs font-bold text-pepper-dark">{pluralCheck(loan.tenure, "Day")}</p>
            </div>
            <div className="flex flex-col py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Interest Rate (30 Days)</p>
              <p className="flex items-center text-xs font-bold text-pepper-dark">{loan.interest}%</p>
            </div>
            <div className="flex flex-col py-2 space-y-2">
              {showDetail && (
                <Button
                  modifier={{ secondary: true, className: "h-8 py-0 text-xs" }}
                  events={{ onClick: () => handleClickDetail(loan.id) }}
                >
                  Detail
                </Button>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  };

  const renderInvoiceList = () => {
    if (loan_detail_error) {
      return <Error title="Something went wrong" subTitle="Please contact the administrator" />;
    }

    return <Invoice invoices={loan_detail_data?.invoices || []} />;
  };

  return (
    <>
      <Modal
        modifier={{ show, classNameContent: "bg-transparent w-[1200px] p-0 top-[25%]" }}
        events={{ closeModal: handleCloseModal }}
      >
        {renderCardContent(loan)}
        {!loading && <div className="mt-8 p-4 bg-white rounded-lg">{renderInvoiceList()}</div>}
      </Modal>
      <div className="mt-8 relative" key={key}>
        {renderCardContent(loan, true)}
      </div>
    </>
  );
};

export default LoanItem;
