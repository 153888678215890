import classNames from "classnames";

import "./Button.css";

const Button = ({ attributes, modifier, events, children }) => {
  const { disabled, secondary, className } = modifier;
  const { id, type } = attributes;
  const { onClick } = events;

  return (
    <button
      className={`btn px-4 py-3 bg-primary w-full text-white rounded-3xl h-12 font-bold text-base outline-none cursor-pointer
      active:bg-primary-darkest hover:bg-primary-darker duration-200
      disabled:bg-pepper-disabled disabled:opacity-50 disabled:text-white disabled:cursor-not-allowed
      ${classNames(className, {
        secondary,
        disabled,
      })}`}
      disabled={disabled}
      id={id}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  attributes: {
    id: "button",
    type: "button",
  },
  modifier: {
    disabled: false,
    secondary: false,
    tertiary: false,
    className: "",
  },
  events: {
    onClick: () => {},
  },
};

export default Button;
