import moment from "moment";
import Card from "components/Card";
import { currency } from "helpers/formatter";
import { CONSTANT } from "config/common";

const InvoiceCard = ({ invoice, key }) => {
  return (
    <div className="mt-8 relative" key={key}>
      <Card>
        <div className=" pb-6">
          <span className="text-sm font-normal text-pepper-dark mr-2">Invoice Number</span>
          <span className="text-sm font-bold">{invoice.invoice_number}</span>
        </div>
        <div className="absolute border-b-[1px] border-pepper-lighter left-0 right-0" />
        <div className="pt-6">
          <div className="grid grid-cols-6 gap-4">
            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Proposed Loan Amount</p>
              <p className="text-xs font-bold text-pepper-dark">{currency(invoice.proposed_loan_amount)}</p>
            </div>
            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Invoice Amount</p>
              <p className="text-xs font-bold text-pepper-dark">{currency(invoice.invoice_amount)}</p>
            </div>
            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Issued Date</p>
              <p className="text-xs font-bold text-pepper-dark">
                {invoice.issued_date ? moment(invoice.issued_date).format("DD MMM YYYY") : CONSTANT.NA}
              </p>
            </div>
            <div className="flex flex-col border-r-[1px] py-1 space-y-2">
              <p className="text-xs text-pepper-dark">Tenure</p>
              <p className="text-xs font-bold text-pepper-dark">{invoice.tenure} Days</p>
            </div>
            <div className="flex flex-col py-1 space-y-2 col-span-2">
              <p className="text-xs text-pepper-dark">Submitted Date</p>
              {invoice.submitted_date ? (
                <div className="flex items-center text-xs font-bold text-pepper-dark">
                  {moment(invoice.submitted_date).format("HH MMMM YYYY")}
                  <div className="w-1 h-1 mx-1 rounded-full bg-pepper-light after:content-[' ']" />
                  {moment(invoice.submitted_date).format("HH:mm")}
                </div>
              ) : (
                <div className="flex items-center text-xs font-bold text-pepper-dark">{CONSTANT.NA}</div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default InvoiceCard;
