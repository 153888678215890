import { useState } from "react";
import moment from "moment";
import Upload from "./Upload";
import { maskingText } from "helpers/formatter";
import { clearIcon, exclamationIcon } from "config/icons";

const Uploader = ({ title, fileType = "-", setTimestamp = () => {} }) => {
  const [filename, setFilename] = useState(null);
  const [uploadDate, setUploadDate] = useState(null);

  const generateTimestamp = () => {
    return moment().format("DD/MM/YYYY . HH:mm");
  };

  const handleFileChange = (file) => {
    const timestamp = generateTimestamp();

    setFilename(file);
    setUploadDate(timestamp);
    setTimestamp(`Latest ${timestamp}`);
    // will do upload file here
  };

  const clearFile = () => {
    setFilename(null);
    setUploadDate(null);
  };

  return (
    <div className="">
      <div className="flex justify-between bg-[#FBFBFB] p-4 rounded-md">
        <div className="flex flex-col">
          <label className="flex text-sm font-medium text-black">
            {title}
            <img className="ml-1" src={exclamationIcon} alt="exclamation-icon" />
          </label>
          <label className="text-sm font-medium text-pepper-light">
            {filename ? (
              <span className="text-pandan-dark mr-4">{maskingText(filename)}</span>
            ) : (
              <span>{fileType}</span>
            )}
            {uploadDate}
          </label>
        </div>
        {filename ? (
          <div className="flex">
            <div className="flex justify-center items-center">
              <Upload hasFileName onChange={handleFileChange} />
            </div>
            <label className="flex items-center hover:cursor-pointer" onClick={clearFile}>
              <img className="ml-2" src={clearIcon} alt="clear-icon" />
            </label>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <Upload onChange={handleFileChange} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Uploader;
