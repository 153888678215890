/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { getCookie } from "helpers/cookies";
import { authActions } from "features/auth.slice";
import { useDispatch, useSelector } from "react-redux";

const Authentication = () => {
  const [params] = useSearchParams();
  const code = params.get("code");
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { getToken } = authActions;

  useEffect(() => {
    dispatch(getToken(code));
  }, [code]);

  if (token) {
    const redirectTo = getCookie("user_dashboard_url");
    return <Navigate to={redirectTo || "/"} />;
  }
  return null;
};

export default Authentication;
