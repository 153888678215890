import { currency } from "helpers/formatter";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

import Button from "components/Button";
import Card from "components/Card";
import Chip from "components/Chips";

const BCCard = ({ limitInformation }) => {
  const navigate = useNavigate();
  const { userDashboardInvoiceSubmission20220912 } = useFlags();

  return (
    <Card attributes={{ bgOn: true }} modifier={{ loading: false }}>
      <div className="grid grid-cols-7 gap-4">
        <div className="flex flex-col border-r-[1px] col-span-2">
          <div className="flex items-center">
            <p className="text-lg font-bold text-shallot-darkest mr-2">OVO Modal Usaha</p>
            <Chip type="success" label="Active" />
          </div>
          <p className="text-sm font-normal text-pepper-dark">
            Onboarded: {moment(limitInformation.on_boarded_date).format("MMMM Do, YYYY") || "N/A"}
          </p>
        </div>
        <div className="flex flex-col border-r-[1px] py-1 space-y-2">
          <p className="text-xs text-pepper-dark">Borrower Total Limit</p>
          <p className="text-xs font-bold text-pepper-dark">{currency(limitInformation.bc_total_limit) || "N/A"}</p>
        </div>
        <div className="flex flex-col border-r-[1px] py-1 space-y-2">
          <p className="text-xs text-pepper-dark">Available Limit</p>
          <p className="text-xs font-bold text-pepper-dark">{currency(limitInformation.available_limit) || "N/A"}</p>
        </div>
        <div className="flex flex-col border-r-[1px] py-1 space-y-2">
          <p className="text-xs text-pepper-dark">Total Active Loans</p>
          <p className="text-xs font-bold text-pepper-dark">{limitInformation.total_active || "N/A"}</p>
        </div>
        <div className="flex flex-col py-1 space-y-2">
          <p className="text-xs text-pepper-dark">Total Remaining O/S</p>
          <p className="text-xs font-bold text-pepper-dark">{currency(limitInformation.total_due) || "N/A"}</p>
        </div>
        <div className="flex flex-col py-2 space-y-2">
          {userDashboardInvoiceSubmission20220912 ? (
            <Button
              modifier={{ className: "h-8 py-0 text-xs" }}
              events={{
                onClick: () => navigate("/loan-application/invoice-form"),
              }}
            >
              Add Invoice
            </Button>
          ) : null}
        </div>
      </div>
    </Card>
  );
};

export default BCCard;
