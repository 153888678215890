import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { applicationActions } from "features/application.slice";
import { setLoading } from "features/loading.slice";

import { ceTeamIllustration } from 'config/images'
import { CONSTANT } from "config/common";

import Error from "components/Card/Error";
import Pagination from "components/Pagination";
import ApplicationItem from "./ApplicationItem";

const Application = () => {
  const { getApplications } = applicationActions;
  const dispatch = useDispatch();
  const { applications: { total_records, data }, error, loading } = useSelector((state) => state.application)
  const { companySelected } = useSelector((state) => state.company)

  const [pagination, setPagination] = useState({
    limit: CONSTANT.LIMIT_PER_PAGE || 10,
    offset: 0
  })

  useEffect(() => {
    const payload = {
      ...(companySelected && { bc_id: companySelected}),
      ...pagination
    }
    dispatch(getApplications(payload))
  }, [pagination, dispatch, getApplications, companySelected])

  dispatch(setLoading(loading));

  const handlePagination = (offset) => {
    setPagination({ ...pagination, offset })
  }

  const renderApplication = applications => {
    if (applications.length === 0) {
      return <Error 
        title="You dont have applications"
        imgIllustation={ceTeamIllustration}
      />
    }
    return applications.map((application, key) => <ApplicationItem application={application} key={key} />)
  }

  return (
    <>
      {!error && renderApplication(data)}
      {!error && data.length > 0 && (
        <div className="mt-8 flex justify-end">
          <Pagination
            itemsPerPage={CONSTANT.LIMIT_PER_PAGE}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            totalData={total_records}
            onChange={handlePagination}
          />
        </div>
      )}
      {error && <Error title={error || "Something went wrong"} subTitle={`Please contact the administrator`} /> }
    </>
  )
}

export default Application;
