import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RESOURCE_API_URL } from "api";
import FetchAPI from "helpers/fetchAPI";

const name = "application"

const getApplications = () => {
  return createAsyncThunk(`${name}/getApplications`, async (payloads, { rejectWithValue }) => {
    try {
      const response = await FetchAPI.post(RESOURCE_API_URL.APPLICATION_LIST, payloads)
      return response?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  })
}

const createInitialState = () => {
  return {
    loading: 0,
    applications: {
      data: [],
      total_records: 0
    },
    error: null
  }
}

const createReducers = () => {
  return {}
}

const createExtraActions = () => {
  return {
    getApplications: getApplications()
  }
}

const createExtraReducers = () => {
  const applications = () => {
    const { pending, fulfilled, rejected } = extraActions.getApplications;
    return {
      [pending]: state => {
        state.loading += 1
      },
      [fulfilled]: (state, action) => {
        state.loading -= 1;
        state.applications = action.payload;
        state.error = null
      },
      [rejected]: (state, action) => {
        state.loading -= 1;
        state.error = action.payload.response_message || action.payload.message
      }
    }
  }
  return { ...applications() }
}

const initialState = createInitialState()
const reducers = createReducers()
const extraActions = createExtraActions()
const extraReducers = createExtraReducers()

export const applicationSlice = createSlice({
  name,
  initialState,
  reducers,
  extraReducers,
})

export const applicationActions = { ...applicationSlice.actions, ...extraActions };
export const applicationReducer = applicationSlice.reducer;