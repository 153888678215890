import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchAPI from "helpers/fetchAPI";
import { toast } from "react-toastify";

const name = "addInvoice";
const reducers = {};
const initialState = {
  loading: 0,
  invoice: {
    id: null,
    pricing: {
      late_interest_rate_per_1_late_day: 0,
      interest_rate_per_30_days: 0,
      admin_fee: 0,
      admin_fee_type: "",
      late_admin_fee: 0,
      late_admin_fee_mode: "",
    },
    invoices: [],
  },
};

const handleExpectedError = (payload) => {
  const { response_code, response_message, message } = payload;
  const errorMessages = ["insufficient loan's remaining limit", "Invalid Parameter"];
  if (response_code !== "OV00003" && !errorMessages.includes(response_message)) {
    toast.error(response_message || message);
  }
};

export const getInvoiceConfiguration = createAsyncThunk(
  `${name}/getInvoiceConfiguration`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await FetchAPI.get("loan/incoming-invoice/create-edit");
      return response?.data?.data?.attributes || {};
    } catch (e) {
      console.error(e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const createInvoice = createAsyncThunk(
  `${name}/createInvoice`,
  async (payload, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { id: incomingInvoiceId } = state.addInvoice.invoice;

      const response = await FetchAPI.post(`loan/incoming-invoice/${incomingInvoiceId}/invoice/create`, payload);
      return response?.data?.data?.attributes?.invoices || {};
    } catch (e) {
      console.error(e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const editInvoice = createAsyncThunk(`${name}/editInvoice`, async (args, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const { id: incomingInvoiceId } = state.addInvoice.invoice;
    const { invoiceId, payload } = args;

    const response = await FetchAPI.post(
      `loan/incoming-invoice/${incomingInvoiceId}/invoice/${invoiceId}/edit`,
      payload
    );
    return response?.data?.data?.attributes || {};
  } catch (e) {
    console.error(e.response.data);
    return rejectWithValue(e.response.data);
  }
});

export const deleteInvoice = createAsyncThunk(
  `${name}/deleteInvoice`,
  async (invoiceId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { id: incomingInvoiceId } = state.addInvoice.invoice;

      const response = await FetchAPI.get(`loan/incoming-invoice/${incomingInvoiceId}/invoice/${invoiceId}/delete`);
      return response?.data?.data || {};
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.response.data);
    }
  }
);

export const submitInvoice = createAsyncThunk(`${name}/submitInvoice`, async (_, { rejectWithValue }) => {
  try {
    const response = await FetchAPI.get("loan/incoming-invoice/submit");
    return response?.data?.data || {};
  } catch (e) {
    console.error(e);
    return rejectWithValue(e.response.data);
  }
});

const createExtraReducers = () => {
  const invoiceConfiguration = () => {
    const { pending, fulfilled, rejected } = getInvoiceConfiguration;

    return {
      [pending]: (state) => {
        state.loading += 1;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading -= 1;
        state.invoice = payload;
      },
      [rejected]: (state, { payload }) => {
        state.loading -= 1;
        toast.error(payload.response_message || payload.message);
      },
    };
  };

  const createInvoiceReducers = () => {
    const { pending, fulfilled, rejected } = createInvoice;

    return {
      [pending]: (state) => {
        state.loading += 1;
      },
      [fulfilled]: (state, { payload }) => {
        state.loading -= 1;
        state.invoice.invoices = payload;
        toast.success("Successfully created new invoice!");
      },
      [rejected]: (state, { payload }) => {
        state.loading -= 1;
        handleExpectedError(payload);
      },
    };
  };

  const editInvoiceReducers = () => {
    const { pending, fulfilled, rejected } = editInvoice;

    return {
      [pending]: (state) => {
        state.loading += 1;
      },
      [fulfilled]: (state) => {
        state.loading -= 1;
      },
      [rejected]: (state, { payload }) => {
        state.loading -= 1;
        handleExpectedError(payload);
      },
    };
  };

  const deleteInvoiceReducers = () => {
    const { pending, fulfilled, rejected } = deleteInvoice;

    return {
      [pending]: (state) => {
        state.loading += 1;
      },
      [fulfilled]: (state) => {
        state.loading -= 1;
        toast.success("Invoice is successfully deleted!");
      },
      [rejected]: (state, { payload }) => {
        state.loading -= 1;
        toast.error(payload.response_message || payload.message);
      },
    };
  };

  const submitInvoiceReducers = () => {
    const { pending, fulfilled, rejected } = submitInvoice;

    return {
      [pending]: (state) => {
        state.loading += 1;
      },
      [fulfilled]: (state) => {
        state.loading -= 1;
      },
      [rejected]: (state, { payload }) => {
        state.loading -= 1;
        toast.error(payload.response_message || payload.message);
      },
    };
  };

  return {
    ...invoiceConfiguration(),
    ...createInvoiceReducers(),
    ...editInvoiceReducers(),
    ...deleteInvoiceReducers(),
    ...submitInvoiceReducers(),
  };
};

const extraReducers = createExtraReducers();

export const addInvoiceSlice = createSlice({ name, initialState, reducers, extraReducers });

export const addInvoiceReducer = addInvoiceSlice.reducer;
