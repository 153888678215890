const AUTH_SERVER = process.env.REACT_APP_GRIFFIN_HOST;
const BASE_API_URL = process.env.REACT_APP_API_URL;
const REFERENCE_SERVER = process.env.REACT_APP_REFERENCE_API_URL;

export const AUTH_API_URL = {
  AUTHORIZE: `${AUTH_SERVER}o/authorize`,
  TOKEN: `${AUTH_SERVER}o/token/`,
  WHOAMI: `${BASE_API_URL}/apis/whoami`,
  LOGIN: `${AUTH_SERVER}accounts/login`,
  LOGOUT: `${AUTH_SERVER}accounts/logout`
};

export const REFERENCE_API_URLS = {
  PROVINCE: `${REFERENCE_SERVER}/province`,
  CITY: `${REFERENCE_SERVER}/city`,
  DISTRICT: `${REFERENCE_SERVER}/district`,
  SUBDISTRICT: `${REFERENCE_SERVER}/villages`,
};

export const RESOURCE_API_URL = {
  SUBMITTED_INVOICE: "loan/submitted",
  APPLICATION_LIST: "loan/applications",
  LOAN_LIST: "loan/loan-list",
  REJECTED_INVOICE: "loan/reject-list"
}

export const USER_DASHBOARD_API_URL = `${process.env.REACT_APP_API_URL}/apis/userdashboard`;
