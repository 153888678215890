import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

import "./Pagination.css";

const arrowLeft = (
  <img alt="Arrow Left" src={require("assets/icons/arrow-left.svg").default} />
);

const arrowRight = (
  <img
    alt="Arrow Right"
    src={require("assets/icons/arrow-right.svg").default}
  />
);

const Pagination = ({
  itemsPerPage = 3,
  totalData = 0,
  pageRangeDisplayed = 3,
  marginPagesDisplayed = 3,
  onChange = () => {},
}) => {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(totalData / itemsPerPage));
  }, [itemsPerPage, totalData]);

  const handlePageChange = ({ selected }) => {
    const offset = selected * itemsPerPage
    onChange(offset);
  };

  return (
    <nav className="p-4 bg-white w-auto rounded-lg inline-block">
      <ReactPaginate
        className="flex list-none select-none"
        breakLabel="..."
        previousLabel={arrowLeft}
        nextLabel={arrowRight}
        previousClassName="min-w-[56px]"
        nextClassName="min-w-[56px]"
        pageClassName="mr-2 last:mr-0"
        previousLinkClassName="page-num active:bg-pepper-light mr-2"
        nextLinkClassName="page-num active:bg-pepper-light"
        pageLinkClassName="page-num active:bg-seasalt-dark active:text-white active:border-seasalt-lighter"
        activeLinkClassName="text-white !bg-seasalt !border-2 !border-seasalt-lightest active:!bg-seasalt-dark active:!border-seasalt-lighter"
        breakLinkClassName="page-num mr-2"
        disabledLinkClassName="cursor-not-allowed"
        onPageChange={handlePageChange}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
      />
    </nav>
  );
};

export default Pagination;
