export const CONSTANT = {
  NA: "N/A",
  LIMIT_PER_PAGE: 10,
  ERROR_MSG_GENERAL: "Something went wrong"
}

export const TOGGLE_KEY = {
  documentUpload: "documentUpload",
  invoiceSubmission: "invoiceSubmission",
};
