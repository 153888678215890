export const REPAYMENT_TYPE = {
  full: "Full Repayment",
  partial: "Partial Repayment"
}

export const REPAYMENT_COLUMN = [
  { name: "Period", dataKey: "period" },
  { name: "Repayment Amount", dataKey: "repayment_amount" },
  { name: "Type", dataKey: "repayment_type" },
  { name: "Transaction ID", dataKey: "transaction_id" }
]