export const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
};

export const ROLE = {
  DIRECTOR: "Director",
  PIC: "PIC",
};

export const YES_NO_OPTIONS = {
  [true]: "Yes",
  [false]: "No",
};

export const EMPLOYMENT_STATUS = {
  PERMANENT: "Permanent",
  CONTRACT: "Contract",
};

export const MARITAL_STATUS = {
  SINGLE: "Single",
  MARRIED: "Married",
  DIVORCED: "Divorced",
};

export const BANK_ACCOUNT_TYPE = {
  CORPORATE_ACCOUNT: "Corporate/Business Account",
  INDIVIDUAL_ACCOUNT: "Individual Account",
  PERSONAL_ACCOUNT: "Personal Account",
};

export const MEMBER_DATA = [
  { label: "Name (as in KTP)", key: "name" },
  { label: "Position Name", key: "position_name" },
  { label: "Gender", key: "gender", mapper: GENDER },
  { label: "KTP ID (NIK)", key: "nik" },
  { label: "Phone Number", key: "phone_number" },
  { label: "Email", key: "email" },
  { label: "Place of Birth", key: "place_of_birth" },
  { label: "Date of Birth", key: "date_of_birth" },
  { label: "Marital Status", key: "marital_status", mapper: MARITAL_STATUS },
  { label: "Latest Education", key: "latest_education" },
  { label: "Employment Status", key: "employment_status", mapper: EMPLOYMENT_STATUS },
  { label: "Role", key: "role", mapper: ROLE },
  { label: "Department", key: "department" },
  { label: "Address (as in KTP)", key: "address" },
  { label: "Province", key: "province_name" },
  { label: "City (Kota/Kabupaten)", key: "city_name" },
  { label: "District (Kecamatan)", key: "district_name" },
  { label: "Sub-District (Kelurahan)", key: "subdistrict_name" },
  { label: "Postal Code", key: "postal_code" },
  { label: "Digisign.id Holder", key: "digisign_holder", mapper: YES_NO_OPTIONS },
  { label: "Registered to digisign.id", key: "digisign_registered", mapper: YES_NO_OPTIONS },
  { label: "Has digisign.id service?", key: "digisign_service", mapper: YES_NO_OPTIONS },
  { label: "Email for digisign.id", key: "digisign_email" },
];

export const BASIC_INFORMATION = [
  { label: "Company Type", key: "company_type" },
  { label: "Legal Name", key: "legal_corporate_name" },
  { label: "Company Identifier (KTP/NPWP)", key: "company_identifier" },
  { label: "Company Phone Number", key: "phone_number" },
  { label: "Industry", key: "industry" },
  { label: "Year of Established", key: "year_of_established" },
  { label: "Number of Employees", key: "number_of_employees" },
  { label: "Number of BOD in the deed", key: "number_of_bod_in_the_deed" },
  { label: "Business Description", key: "business_desc" },
];

export const ADDRESS = [
  { label: "Company Address", key: "address" },
  { label: "Province", key: "province_name" },
  { label: "City", key: "city_name" },
  { label: "District", key: "district_name" },
  { label: "Sub-District", key: "subdistrict_name" },
  { label: "Postal Code", key: "postal_code" },
];

export const BANK_ACCOUNT = [
  { label: "Bank Account Name", key: "bank_account_name" },
  { label: "Bank Name", key: "bank_name" },
  { label: "Bank Account Number", key: "bank_account_number" },
  { label: "Bank Account Type", key: "bank_account_type", mapper: BANK_ACCOUNT_TYPE },
];
