export const Health = () => {
  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold text-green-700">
        Welcome to User Dashboard!
      </h1>
      <p className="mt-2">Version: {process.env.REACT_APP_VERSION}</p>
    </div>
  );
};

export default Health;
