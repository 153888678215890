import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Action
import { invoiceActions } from 'features/invoice.slice';
import { setLoading } from 'features/loading.slice';

// Component
import InvoiceCard from './Invoice/InvoiceCard';
import Pagination from 'components/Pagination';
import Error from 'components/Card/Error';

// Helpers
import { ceTeamIllustration } from 'config/images';

const LIMIT_PER_PAGE = 10;

const RejectedInvoice = () => {
  const [pagination, setPagination] = useState({
    limit: LIMIT_PER_PAGE || 10,
    offset: 0,
  });
  const dispatch = useDispatch();
  const { getRejectedInvoice } = invoiceActions;
  const { rejected: { invoices, error, total }, loading } = useSelector((state) => state.invoice);
  const { companySelected } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(getRejectedInvoice({
      ...(companySelected && { bc_id: companySelected }),
      ...pagination
    }));
  }, [pagination, dispatch, getRejectedInvoice, companySelected]);

  dispatch(setLoading(loading));

  const onChangePagination = (offset) => {
    setPagination({ ...pagination, offset });
  };

  const renderInvoice = (invoices) => {
    if (invoices.length === 0) {
      return (
        <Error title="You don't have rejected invoice." imgIllustation={ceTeamIllustration} />
      );
    }
    return invoices.map((invoice, key) => <InvoiceCard invoice={invoice} key={key} />);
  };

  return (
    <>
      {!error && renderInvoice(invoices)}
      {!error && invoices.length > 0 && (
        <div className="my-8 flex justify-end">
          <Pagination
            itemsPerPage={LIMIT_PER_PAGE}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            totalData={total}
            onChange={onChangePagination}
          />
        </div>
      )}
      {error && <Error title={error} subTitle="Please contact the administrator" />}
    </>
  );
};

export default RejectedInvoice;
