
const Table = ({ columns = [], data = [] }) => {
  const columnIndex = columns.map((column) => column.dataKey);
  return (
    <div className="w-full m-auto">
      <table className="table-fixed w-full bg-white">
        <thead className="bg-[#FBFBFB] text-black text-sm font-bold">
          <tr className="bg-[#FBFBFB]">
            {columns.map((column, index) => (
              <td key={index} className="first:rounded-l-[10px] last:rounded-r-[10px] p-4">{column.name}</td>
            ))}
          </tr>
        </thead>
        <tbody className="text-pepper-dark text-sm font-medium">
          {data.length > 0 && data.map((item, index) => (
            <tr key={index} className="odd:bg-white even:bg-[#FBFBFB]">
              {columnIndex.map((colIdx, idx) => (
                <td key={idx} className="first:rounded-l-[10px] last:rounded-r-[10px] p-4">{item[colIdx] || "-"}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {data.length < 1 && (
        <div className="text-center p-4">
          <span className="text-pepper-dark text-sm font-normal">No data</span>
        </div>
      )}
    </div>
  )
}

export default Table;
