import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import "./Toast.css";

const Toast = () => {
  return (
    <ToastContainer
      className="!min-w-[500px] text-sm font-bold"
      position="top-center"
      autoClose={false}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      closeButton={true}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};

export default Toast;
