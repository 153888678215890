import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

import { leftArrowIcon } from "config/icons";

import ProgressBar from "components/ProgressBar";
import Card from "components/Card";

import "./LoanApplicationLayout.css";

export const LoanApplicationLayout = ({ children, cardWidth = "w-[760px]", progressBarStep = 1 }) => {
  const { pathname } = window.location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const navigate = useNavigate();
  const { userDashboardDocumentUpload20220825 } = useFlags();

  const labels = userDashboardDocumentUpload20220825
    ? ["Loan Application", "Document Upload", "Terms & Conditions"]
    : ["Loan Application", "Terms & Conditions"];

  return (
    <div className="loan-application-bg pt-4 !bg-white pb-8 px-6 -mx-6">
      <div className="max-w-6xl m-auto">
        <button className="flex mb-8" onClick={() => navigate("/portfolio/detail")}>
          <img src={leftArrowIcon} alt="Arrow Left" />
          <span className="text-sm font-bold py-0.5">Back to Home</span>
        </button>
        <div className="w-1/2 mx-auto pb-16">
          <ProgressBar currentStep={progressBarStep} labels={labels} />
        </div>
        <div className={`mx-auto ${cardWidth}`}>
          <Card>{children}</Card>
        </div>
      </div>
    </div>
  );
};

export default LoanApplicationLayout;
