import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import reducer from "./reducers";

const store = configureStore({
  reducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production" || false,
});

export default store;
