import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RESOURCE_API_URL } from "api";
import { CONSTANT } from "config/common";
import FetchAPI from "helpers/fetchAPI";

const name = "invoice";
const initialState = createInitialState();
const reducers = {};
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

export const invoiceSlice = createSlice({ name, initialState, reducers, extraReducers })

export const invoiceActions = { ...invoiceSlice.actions, ...extraActions }
export const invoiceReducer = invoiceSlice.reducer

function createInitialState() {
  return {
    loading: 0,
    submitted: {
      total: 0,
      invoices: [],
      error: false
    },
    rejected: {
      total: 0,
      invoices: [],
      error: false
    }
  }
}

function createExtraActions() {
  return {
    getSubmittedInvoice: getSubmittedInvoice(),
    getRejectedInvoice: getRejectedInvoice()
  };    

  function getSubmittedInvoice() {
    return createAsyncThunk(
      `${name}/getSubmittedInvoice`,
      async (payload, { rejectWithValue }) => {
        try {
          const response = await FetchAPI.post(RESOURCE_API_URL.SUBMITTED_INVOICE, payload)
          return response?.data
        } catch (error) {
          return rejectWithValue(error.response.data)
        }
      }
    );
  }
  function getRejectedInvoice() {
    return createAsyncThunk(
      `${name}/getRejectedInvoice`,
      async (payload, { rejectWithValue }) => {
        try {
          const response = await FetchAPI.post(RESOURCE_API_URL.REJECTED_INVOICE, payload)
          return response?.data
        } catch (error) {
          return rejectWithValue(error.response.data)
        }
      }
    );
  }
}

function createExtraReducers() {
  return {
    ...getSubmittedInvoice(),
    ...getRejectedInvoice()
  }
  function getSubmittedInvoice() {
    const { pending, fulfilled, rejected } = extraActions.getSubmittedInvoice
    return {
      [pending]: state => {
        state.error = null
        state.loading += 1;
      },
      [fulfilled]: (state, action) => {
        const { data, total_records } = action.payload
        const invoices = data.map(invoice => {
          return {
            invoice_amount: invoice?.attributes?.invoice_amount,
            invoice_number: invoice?.attributes?.invoice_number,
            tenure: invoice?.attributes?.tenure,
            issued_date: invoice?.attributes?.issued_date,
            submitted_date: invoice?.attributes?.submitted_date,
            proposed_loan_amount: invoice?.attributes?.proposed_loan_amount
          }
        })
        state.loading -= 1;
        state.submitted.invoices = invoices
        state.submitted.total = total_records
        state.submitted.error = null
      },
      [rejected]: (state, action) => {
        state.loading -= 1;
        state.submitted.error = action.payload.response_message || action.payload.message || CONSTANT.ERROR_MSG_GENERAL
      }
    }
  }

  function getRejectedInvoice() {
    const { pending, fulfilled, rejected } = extraActions.getRejectedInvoice
    return {
      [pending]: state => {
        state.error = null
        state.loading += 1;
      },
      [fulfilled]: (state, action) => {
        const { data, total_records } = action.payload
        const invoices = data.map(invoice => {
          return {
            invoice_amount: invoice?.invoice_amount,
            invoice_number: invoice?.invoice_number,
            tenure: invoice?.tenure,
            issued_date: invoice?.issued_date,
            submitted_date: invoice?.submitted_date,
            proposed_loan_amount: invoice?.proposed_loan_amount
          }
        })
        state.loading -= 1;
        state.rejected.invoices = invoices
        state.rejected.total = total_records
        state.rejected.error = null
      },
      [rejected]: (state, action) => {
        state.loading -= 1;
        state.rejected.error = action.payload.response_message || action.payload.message || CONSTANT.ERROR_MSG_GENERAL
      }
    }
  }
}
