import classNames from "classnames";

const Modal = ({ events, modifier, children }) => {
  const { show, classNameContent } = modifier;
  const { closeModal } = events;

  const modalVisibility = show ? "block" : "hidden";
  const translatePosition = show ? "translate-y-0" : "translate-y-[-100vh]";

  return (
    <div className="flex items-center justify-center">
      <div
        className={`fixed w-full h-full left-0 right-0 top-0 bottom-0 z-40 b bg-black/50 cursor-pointer
        ${classNames(modalVisibility)}`}
        onClick={closeModal}
      />

      <div
        className={`fixed z-50 bg-white w-[700px] p-6 top-[30%] ease-in-out duration-300 rounded-xl
        ${classNames(classNameContent)}
        ${classNames(translatePosition)}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
