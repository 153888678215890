/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { setLoading } from "features/loading.slice";
import { submitInvoice } from "features/add-invoice.slice";
import { arrowDownIcon } from "config/icons";

import LoanApplicationLayout from "layouts/LoanApplicationLayout";
import TermsConditionsContent from "./Content";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import AlertModal from "components/Modal/AlertModal";

const TermsConditions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const endPageRef = useRef(null);
  const { userDashboardDocumentUpload20220825 } = useFlags();

  const [isChecked, toggleChecked] = useState(false);
  const [showScrollButton, toggleScrollButton] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const { loading } = useSelector((state) => state.addInvoice);

  const checkScrollPosition = () => {
    const currentScroll = window.scrollY;
    const windowHeight = window.innerHeight;
    const maxScroll = document.body.scrollHeight - windowHeight;

    toggleScrollButton(currentScroll > windowHeight / 10 && currentScroll < maxScroll);
  };

  useEffect(() => {
    checkScrollPosition();
    window.addEventListener("scroll", checkScrollPosition);

    return () => window.removeEventListener("scroll", checkScrollPosition);
  }, []);

  const scrollToBottom = () => {
    endPageRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading]);

  const handleSubmitInvoice = async () => {
    const response = await dispatch(submitInvoice());
    const { requestStatus } = response.meta;
    if (requestStatus === "fulfilled") toggleModal(true);
  };

  return (
    <LoanApplicationLayout progressBarStep={userDashboardDocumentUpload20220825 ? 3 : 2}>
      <TermsConditionsContent />
      <div className="rounded-lg mt-16 shadow-[0px_0px_12px_rgba(0,0,0,0.08)] py-4 px-6">
        <Checkbox
          attributes={{
            label: "I agree with the Terms & Conditions, Privacy Policy, and OVO Modal Usaha Terms & Conditions",
          }}
          modifier={{
            disabled: false,
            labelClassName: "text-sm text-pepper-darker",
            checkBoxClassName: "cursor-pointer",
          }}
          events={{
            onCheck: (value) => toggleChecked(value),
          }}
        />
      </div>

      <div ref={endPageRef} className="mt-10 flex justify-end">
        <button
          onClick={() =>
            userDashboardDocumentUpload20220825
              ? navigate("/loan-application/document")
              : navigate("/loan-application/invoice-form")
          }
          className="font-bold text-pepper-dark mr-7"
        >
          Back
        </button>
        <Button
          modifier={{
            disabled: !isChecked,
            className: "w-[144px]",
          }}
          events={{
            onClick: handleSubmitInvoice,
          }}
        >
          Submit
        </Button>
      </div>

      {showScrollButton && (
        <div className="fixed bottom-8 left-0 right-0 flex justify-center items-center">
          <Button
            modifier={{
              className: "w-[252px] shadow-[0px_0px_20px_rgba(0,0,0,0.2)]",
            }}
            events={{
              onClick: scrollToBottom,
            }}
          >
            <div className="flex justify-center items-center">
              <img src={arrowDownIcon} alt="Arrow Down" />
              <p className="ml-2">Click to Scroll Down</p>
            </div>
          </Button>
        </div>
      )}

      <AlertModal
        modifier={{
          show: showModal,
          heading: "Your loan application is submitted",
          subheading: "Now please wait until our team reach you back via email no later than 3 business days.",
          imageSrc: "https://lending-assets.oss-ap-southeast-5.aliyuncs.com/webview/illustration/clap.svg",
          imageAlt: "People clapping hand together",
        }}
        events={{
          closeModal: () => navigate("/portfolio/detail"),
          handleConfirm: () => navigate("/portfolio/detail"),
        }}
      />
    </LoanApplicationLayout>
  );
};

export default TermsConditions;
