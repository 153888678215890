import moment from "moment"

import Table from "components/Table"
import { currency, pluralCheck } from "helpers/formatter"
import { CONSTANT } from "config/common"

const Invoice = ({ invoices }) => {
  const columns = [
    { name: "Invoice Number", dataKey: "invoice_number" },
    { name: "Proposed Loan Amount", dataKey: "proposed_loan_amount" },
    { name: "Invoice Amount", dataKey: "invoice_amount" },
    { name: "Issued Date", dataKey: "issued_date" },
    { name: "Tenure", dataKey: "tenure" },
    { name: "Submitted Date", dataKey: "submitted_date" },
  ]
  const data = invoices.map(invoice => ({
    invoice_number: invoice.invoice_number || CONSTANT.NA,
    proposed_loan_amount: currency(invoice.proposed_loan_amount) || CONSTANT.NA,
    invoice_amount: currency(invoice.invoice_amount) || CONSTANT.NA,
    issued_date: invoice.issued_date ? moment(invoice.issued_date).format("DD MMMM YYYY") : CONSTANT.NA,
    tenure: invoice.tenure ? pluralCheck(invoice.tenure, "day") : CONSTANT.NA,
    submitted_date: moment(invoice.submitted_date).format("DD MMMM YYYY") || CONSTANT.NA
  }))
  return (
    <Table
      columns={columns} 
      data={data}
    />
  )
}

export default Invoice
